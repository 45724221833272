import { Component, OnInit, ViewChild, HostListener, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DefaultService, Book, PhysicalSupport, Person, Hand, BookSeries, Reference, Publication, Location as Loc  } from '../rest';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { Observable, of, forkJoin, merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap, startWith } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionModel } from '@angular/cdk/collections'
import { AppComponent } from '../app.component';
import { NgForm } from '@angular/forms';
import { ComponentCanDeactivate } from '../pending-changes.guard';
import { PublicationDataSource } from '../publications/publications.component';
import {COMMA, ENTER, SEMICOLON, FF_SEMICOLON, SHIFT} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { PersonDetailModal } from '../persons/persons.component';
import * as d3 from 'd3';
import *  as L from 'leaflet';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { animation } from '@angular/animations';

@Component({
  selector: 'ngbd-modal-confirm',
  templateUrl: './document-detail-publication.component.html',
  styleUrls: ['./document-detail.component.css']
})
export class PublicationSearchModal implements OnInit {
  constructor(public modal: NgbActiveModal,
    private documentApi: DefaultService) { }

  @Input() public user;
  publicationDisplayedColumns = ['id', 'publication', 'authors', 'title', 'number', 'year', 'url', 'pages', 'place', 'plate', 'createdAt', 'userCreation'];
  publicationsDataSource: PublicationDataSource;
  lastPublicationFilter = ""
  @ViewChild('lastPublicationFilterVC', { static: false }) lastPublicationFilterVC: ElementRef;
  @ViewChild('publicationPaginator', { read: MatPaginator, static: false }) publicationPaginator: MatPaginator;
  @ViewChild('publicationSort', { read: MatSort, static: false }) publicationSort: MatSort;

  loadPublications(filterValue: string = '', pageIndexReset = true) {
    this.lastPublicationFilter = filterValue
    if (pageIndexReset == true) {
      this.publicationPaginator.pageIndex = 0
    }
    this.publicationsDataSource.loadPublications(
      filterValue,
      this.publicationSort.active,
      this.publicationSort.direction,
      this.publicationPaginator.pageIndex,
      this.publicationPaginator.pageSize,
      null, null, null
    )
  }

  ngOnInit() {
    this.publicationsDataSource = new PublicationDataSource(this.documentApi, this.user.email, this.user.idToken)
    this.publicationsDataSource.loadPublications()
  }

  ngAfterViewInit() {
    merge(this.publicationSort.sortChange, this.publicationPaginator.page)
      .pipe(
        tap(() => this.loadPublications(this.lastPublicationFilter, false))
      )
      .subscribe();
    fromEvent(this.lastPublicationFilterVC.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => this.loadPublications(text));
  }
}

@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.css']
})
export class DocumentDetailComponent extends ComponentCanDeactivate implements OnInit {
  searchPublications() {
    const modalRef = this.modalService.open(PublicationSearchModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass' })
    modalRef.componentInstance.user = this.mainApp.user
    modalRef.result.then((result) => {
      let OldCitation = this.addBibliographyDetail.citations
      let OldWindowId = this.addBibliographyDetail.idWindowDbg
      let OldWindowTitle = this.addBibliographyDetail.windowTitle
      this.addBibliographyDetail = Object.assign({}, result)
      this.addBibliographyDetail.createNew = false
      this.addBibliographyDetail.citations = OldCitation
      this.addBibliographyDetail.idWindowDbg = OldWindowId
      this.addBibliographyDetail.windowTitle = OldWindowTitle
    }, (reason) => {
    });
  }

  listsModified: boolean = false

  handSelection: SelectionModel<any> = new SelectionModel<any>(false, []);
  gsSelection: SelectionModel<any> = new SelectionModel<any>(false, []);
  assignedHandSelection: SelectionModel<any> = new SelectionModel<any>(false, []);
  show: boolean = false;
  id: any;
  oldNewBiblioValue: string = "newbiblio"
  docdetail: any = {
    lastNewIdentifierId: -1,
    lastNewEditionId: -1,
    lastNewDigitalReproductionId: -1,
    lastNewHandId: -1,
    lastNewGraphicSymbolId: -1,
    lastNewBibliographyId: -1,
    lastNewCitationId: -1,
    principalIdentifier: null,
    principalIdentifierEx: null,
    hands: {},
    otherIdentifiers: {},
    otherIdentifiersEx: {},
    editions: {},
    editionsEx: {},
    digitalReproductions: {},
    bibliography: {},
    bibliographyEx: {},
    physicalSupports: {},
    printedFacsimile: {},
    printedFacsimileEx: {}
  };
  gsDetail: any = {
  };
  gsElementArray: any = {}
  addBookV2Detail: any = {
    bookSeriesId: null,
    bookSeries: null,
    title: null,
    volume: null,
    authors: null,
    place: null,
    year: null
  }
  addBookSeriesDetail: any = {
    title: null,
    abbreviation: null,
    containerSeries: null,
    containerSeriesId: null
  }
  addContainerBookSeriesDetail: any = {
    title: null,
    abbreviation: null,
  }
  addIdentifierDetail: any = {
    number: null,
    bookDescription: null,
    bid: null
  }
  addEditionDetail: any = {
    number: null,
    bookDescription: null,
    bid: null,
    pages: null
  }
  addDigitalReproductionDetail: any = {
    url: null
  }
  addHandDetail: any = {
    ordinal: null,
    script: null,
    comment: null,
    position: null,
    person: null,
    personId: null,
    uncertainAssignment: false,
    handTags: []
  }
  addCitationDetail: any = {}
  addBibliographyDetail: any = {
    authors: null,
    title: null,
    publication: null,
    year: null,
    tome: null,
    bookDescription: null,
    bid: null,
    url: null,
    pages: null,
    number: null
  }

  addLocationOriginDetail: any = {}
  addContainerLocationOriginDetail: any = {}
  associatedLocationOrigin = null

  addLocationOriginDetail2: any = {}
  addContainerLocationOriginDetail2: any = {}
  associatedLocationOrigin2 = null

  addLocationProvenanceDetail: any = {}
  addContainerLocationProvenanceDetail: any = {}
  associatedLocationProvenance = null

  addLocationProvenanceDetail2: any = {}
  addContainerLocationProvenanceDetail2: any = {}
  associatedLocationProvenance2 = null

  updated: boolean = false;
  handsDataSource = new MatTableDataSource();
  assignedToHandsDataSource = new MatTableDataSource();
  identifiersDataSource = new MatTableDataSource();
  identifiersDataSourceEx = new MatTableDataSource();
  mainIdentifierDataSource = new MatTableDataSource();
  mainIdentifierDataSourceEx = new MatTableDataSource();
  editionsDataSource = new MatTableDataSource();
  editionsDataSourceEx = new MatTableDataSource();
  printedFacsimileDataSource = new MatTableDataSource();
  printedFacsimileDataSourceEx = new MatTableDataSource();
  graphicSymbolDataSource = new MatTableDataSource();
  digitalReproductionDataSource = new MatTableDataSource();
  bibliographyDataSource = new MatTableDataSource();
  bibliographyDataSourceEx = new MatTableDataSource();
  physicalSupportsDataSource = new MatTableDataSource();
  closeResult: string;
  handSelected: number = null;
  handAssignedSelected = this.handSelected;
  showAddGSbtn: boolean = false;
  gsSelected: number = null;
  newGsRequired: boolean = false;
  currentPictureX = null;
  addReferenceDetail: any = {};
  originalArchiveList = new Set();
  dateTags: string[] = [];
  //set the data to dataSource property

  
  // select "century" field
  centuryList: string[] =  [
    '200-249','250-299','300-349','350-399',
    '400-449','450-499','500-549','550-599',
    '600-649','650-699','700-749','750-799']
  selectedCentury: string[] = [];

  dropdownCenturySettings:IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 15,
    allowSearchFilter: false,
    enableCheckAll:false,
    maxHeight: 400
  };

  // select "type" field
  typeList: string[] =  ['Documents (issued) by rulers',
    'Documents and letters (issued) by officials, bishops, abbots' ,
    'Official proceedings and courts proceedings' ,
    'Written records related to the state administration (fiscal, military et sim.)' ,
    'Petitions, complaints, letters, declarations to rulers, officials, bishops, abbots',
    'Written records related to disputes, dispute settlements, compromises, arbitrations, private agreements' ,
    'Documents recording negotia and contracts' ,
    'Documents recording wills, testaments, bequests' ,
    'Written records related to private business sphere, trade activity, estate management' ,
    'Lists, inventories, registers' ,
    'Labels' ,
    'Private letters' ,
    'Writing exercises / school exercises' ,
    'Written records related to religious and magical sphere' ,
    'Fragments and written records of uncertain nature or content' ];
  selectedTypes: string[] = [];
  dropdownSettings:IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 15,
    allowSearchFilter: false,
    enableCheckAll:false,
    maxHeight: 400
  };

  // multiple authors
  authorList: string[] =  ['antonella.ghignoli@uniroma1.it', 'nina.sietis@uniroma1.it', 'anna.monte@uniroma1.it'
 , 'maria.boccuzzi@uniroma1.it', 'livia.briasco@uniroma1.it' , 'aneta.skalec@uniroma1.it'
 , 'marta.marucci@uniroma1.it', 'luciaconsuelo.colella@uniroma1.it']
  selectedAuthors: string[] = [];
  selectedAuthorsGS: string[] = [];

  dropdownAuthorSettings:IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 15,
    allowSearchFilter: false,
    enableCheckAll:false,
    maxHeight: 400
  };

  isSaving: boolean = false;

  @ViewChild('documentForm', { static: false })
  documentForm: NgForm;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => { if (term.length == 0) {this.docdetail.PSDescription = null} return [] }))
        : this.documentApi.readAll(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, 'id', 'asc', term).pipe(
          map(value => {
            if (value.physicalSupports.length > 0) {
              return value.physicalSupports
            } else {
              return []
            }
          })
        ))
    )

  referenceSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => { if (term.length == 0) {this.addCitationDetail.reference = null; this.addCitationDetail.id = null} return [] }))
        : this.documentApi.readAllReferences(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, null, null, term, null, null).pipe(
          map(value => {
            if (value.references.length > 0) {
              return value.references
            } else {
              return []
            }
          })
        ))
    )

  bookSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => { if (term.length == 0) {this.addBibliographyDetail.book = null} return [] }))
        : this.documentApi.readBook(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null, null).pipe(
          map(value => {
            if (value.books.length > 0) {
              return value.books
            } else {
              return []
            }
          })
        ))
    )

  bookSeriesBookSeriesSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => { if (term.length == 0) {this.addBookSeriesDetail.containerSeries = null; this.addBookSeriesDetail.containerSeriesId = null} return [] }))
        : this.documentApi.readBookSeries(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null).pipe(
          map(value => {
            if (value.bookSeries.length > 0) {
              return value.bookSeries
            } else {
              return []
            }
          })
        ))
    )

  bookBookSeriesSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => { if (term.length == 0) {this.addBookV2Detail.bookSeries = null; this.addBookV2Detail.bookSeriesId = null;} return [] }))
        : this.documentApi.readBookSeries(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null).pipe(
          map(value => {
            if (value.bookSeries.length > 0) {
              return value.bookSeries
            } else {
              return []
            }
          })
        ))
    )

  personSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 1 ? of([]).pipe(map(value => { if (term.length == 0) {this.addHandDetail.person = null; this.addHandDetail.personId = null; this.addHandDetail.personDescription = null} return [] }))
        : this.documentApi.readP(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term).pipe(
          map(value => {
            if (value.persons.length > 0) {
              return value.persons
            } else {
              return []
            }
          })
        ))
    )

  stringifyReference(x: Reference, includeTitle: boolean): string {
      if (x.id == null) {
        return null
      }
      return x.referenceText + " - " + x.type + (includeTitle ? (x.associatedPublication != null && x.associatedPublication.title != null ? " - " + x.associatedPublication.title : (x.title != null ? " - " + x.title : "")) : "");
    }

  stringifyPhysicalSupport(x: PhysicalSupport): string {
    if (x.id == null) {
      return null
    }
    return x.city + ' ' + x.conservationPlace + ' ' + x.inventory;
  }
  bibliographyStringifyBook(x: Book): string {
    if (x.id == null) {
      return null
    }
    return x.title;
  }
  stringifyPerson(x: Person): string {
    if (x.id == null) {
      return null
    }
    return x.id + " - " + x.name + (x["function"] == null ? (x["_function"] == null ? "" : " - " + x["_function"]) : " - " + x["function"]) + (x["status"] == null ? (x["status"] == null ? "" : " - " + x["status"]) : " - " + x["status"]) + (x["date"] == null ? (x["date"] == null ? "" : " - " + x["date"]) : " - " + x["date"])
  }

  infoPerson(person: Person, event: Event) {
    const modalRef = this.modalService.open(PersonDetailModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass'})
    modalRef.componentInstance.toModify = true
    modalRef.componentInstance.showSaveBtn = false
    modalRef.componentInstance.mainApp = this.mainApp
    modalRef.componentInstance.addPersonDetail = person
    modalRef.result.then((result) => {
      this.addHandDetail.person = result;
      this.addHandDetail.personId = result.id;
    }, (reason) => {
    }).catch((res) => {});
  }

  stringifyBookSeries(x: BookSeries): string {
    if (x.id == null) {
      return null
    }
    return x.abbreviation + (x.extendedTitle == null ? "" : " - " + x.extendedTitle);
  }
  stringifyContainerBookSeries(x: BookSeries): string {
    if (x.id == null) {
      return null
    }
    return x.abbreviation + (x.extendedTitle == null ? "" : " - " + x.extendedTitle);
  }

  formatter = (x: PhysicalSupport) => {
    if (!(x.id in this.docdetail.physicalSupports) || this.docdetail.physicalSupports[x.id].deleted == true) {
      x.linkId = -1
      this.docdetail.physicalSupports[x.id] = x
      this.physicalSupportsDataSource.data.push(x);
      this.physicalSupportsDataSource.data = this.physicalSupportsDataSource.data
    }
    return ""
  }

  bibliographyBookFormatter = (x: Book) => {
    this.addBibliographyDetail.year = x.year
    return this.bibliographyStringifyBook(x)
  }
  personFormatter = (x: Person) => {
    this.addHandDetail.personId = x.id
    return this.stringifyPerson(x)
  }
  identifierBookSeriesFormatter = (x: BookSeries) => {
    this.addBookV2Detail.bookSeriesId = x.id
    this.addReferenceDetail.bookSeriesId = x.id
    return this.stringifyBookSeries(x)
  }
  identifierContainerBookSeriesFormatter = (x: BookSeries) => {
    this.addBookSeriesDetail.containerSeriesId = x.id
    return this.stringifyContainerBookSeries(x)
  }
  referenceFormatter = (x: Reference) => {
    this.addCitationDetail.id = x.id
    this.addCitationDetail.referenceText = x.referenceText
    return this.stringifyReference(x, true)
  }

  /*map(term => term.length < 2 ? []
    : states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))*/

  displayedColumns = ['select', 'id', 'ordinal', 'position', 'person', 'script', 'comment', 'delete'];
  gsDisplayedColumns = ['select', 'id', 'handOrdinal', 'shape', 'description', 'position', 'tags', 'image', 'delete'];
  assignedToHandsdisplayedColumns = ['select', 'person', 'id', 'ordinal'];
  identifierDisplayedColumns = ['id', 'book', 'number', 'dsnumber', 'dspage', 'delete'];
  identifierDisplayedColumnsEx = ['id', 'reference', 'dsnumber', 'dspage', 'delete'];
  mainIdentifierDisplayedColumns = ['id', 'book', 'number', 'dsnumber', 'dspage'];
  mainIdentifierDisplayedColumnsEx = ['id', 'reference', 'dsnumber', 'dspage'];
  editionDisplayedColumns = ['id', 'authors', 'title', 'publication', 'year', 'number', 'pages', 'dsnumber', 'dspage', 'dstome', 'delete'];
  editionDisplayedColumnsEx = ['id', 'reference', 'dsnumber', 'dspage', 'dstome', 'delete'];
  digitalReproductionColumns = ['id', 'url', 'comment', 'delete'];
  bibliographyColumns = ['id', 'authors', 'title', 'publication', 'year', 'number', 'pages', 'dsnumber', 'dspage', 'dstome', 'delete'];
  bibliographyColumnsEx = ['id', 'reference', 'dsnumber', 'dspage', 'dstome', 'delete'];
  physicalSupportDisplayedColumns = ['id', 'city', 'conservationPlace', 'inventory', 'delete'];
  printedFacsimileDisplayedColumns = ['id', 'authors', 'title', 'publication', 'year', 'number', 'pages', 'plate', 'dsnumber', 'dspage', 'dstome', 'dsplate', 'delete'];
  printedFacsimileDisplayedColumnsEx = ['id', 'reference', 'dsnumber', 'dspage', 'dstome', 'dsplate', 'delete'];
  

  @ViewChild('handForm', { read: NgForm, static: false }) gsForm;
  @ViewChild('physicalSupportPaginator', { read: MatPaginator, static: false }) physicalSupportPaginator: MatPaginator;
  @ViewChild('physicalSupportSort', { read: MatSort, static: false }) physicalSupportSort: MatSort;
  @ViewChild('printedFacsimilePaginator', { read: MatPaginator, static: false }) printedFacsimilePaginator: MatPaginator;
  @ViewChild('printedFacsimileSort', { read: MatSort, static: false }) printedFacsimileSort: MatSort;
  @ViewChild('handPaginator', { read: MatPaginator, static: false }) handPaginator: MatPaginator;
  @ViewChild('identifierPaginator', { read: MatPaginator, static: false }) identifierPaginator: MatPaginator;
  @ViewChild('editionPaginator', { read: MatPaginator, static: false }) editionPaginator: MatPaginator;
  @ViewChild('digitalReproductionPaginator', { read: MatPaginator, static: false }) digitalReproductionPaginator: MatPaginator;
  @ViewChild('gsPaginator', { read: MatPaginator, static: false }) gsPaginator: MatPaginator;
  @ViewChild('bibliographyPaginator', { read: MatPaginator, static: false }) bibliographyPaginator: MatPaginator;
  @ViewChild('handSort', { read: MatSort, static: false }) sort: MatSort;
  @ViewChild('identifierSort', { read: MatSort, static: false }) identifiersort: MatSort;
  @ViewChild('editionSort', { read: MatSort, static: false }) editionsort: MatSort;
  @ViewChild('digitalReproductionSort', { read: MatSort, static: false }) digitalreproductionsort: MatSort;
  @ViewChild('gsSort', { read: MatSort, static: false }) gssort: MatSort;
  @ViewChild('biblioSort', { read: MatSort, static: false }) bibliographySort: MatSort;

  @ViewChild('printedFacsimilePaginatorEx', { read: MatPaginator, static: false }) printedFacsimilePaginatorEx: MatPaginator;
  @ViewChild('printedFacsimileSortEx', { read: MatSort, static: false }) printedFacsimileSortEx: MatSort;
  @ViewChild('bibliographyPaginatorEx', { read: MatPaginator, static: false }) bibliographyPaginatorEx: MatPaginator;
  @ViewChild('bibliographySortEx', { read: MatSort, static: false }) bibliographySortEx: MatSort;
  @ViewChild('editionPaginatorEx', { read: MatPaginator, static: false }) editionPaginatorEx: MatPaginator;
  @ViewChild('editionSortEx', { read: MatSort, static: false }) editionsortEx: MatSort;
  @ViewChild('identifierPaginatorEx', { read: MatPaginator, static: false }) identifierPaginatorEx: MatPaginator;
  @ViewChild('identifierSortEx', { read: MatSort, static: false }) identifiersortEx: MatSort;
  
  constructor(
    private route: ActivatedRoute,
    private documentApi: DefaultService,
    private location: Location,
    private router: Router,
    private modalService: NgbModal,
    private mainApp: AppComponent
  ) { super() }
  
  tags: string[] = [];
  suggestedTags: string[] = [];

  private addTagsFromDB(): void {
    this.documentApi.retrieveTags('document-detail', this.id, "tags", this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          var results = value.value.split('||^||').filter(Boolean);
          this.tags = results
        } else {
          this.tags = []
        }
      }
    );
  }

  associatedPublicationFormatter = (x: Publication) => {
    return this.stringifyAssociatedPublication(x)
  }

  stringifyAssociatedPublication(x: Publication): string {
    if (x.id == null) {
      return null
    }
    if (x.type == 'Journal Issue' || x.type == 'Collection') {
      let abbr = (x.bookSeries != null ? x.bookSeries.abbreviation : null);
      if (abbr == null || abbr == "") abbr = "[no series]";
      let volume = x.volume;
      if (volume == null || volume == "") volume = "[no volume]";
      if ((x.bookSeries == null || x.bookSeries.abbreviation == null) && (x.volume == null || x.volume == "")) {
        return x.title + (x.year == null ? "[no year]" : x.year);
      } else {
        return abbr + " " + volume + " " + (x.year == null ? "[no year]" : x.year);
      }
    } else if (x.type == 'Monograph' || x.type == 'Journal Paper' || x.type == 'Monograph Paper') {
      let authors = x.authors;
      if (authors == null || authors == "") authors = "[no authors]";
      return authors + " " + (x.year == null ? "[no year]" : x.year) + " - " + (x.title == null ? "[no title]" : x.title);
    } else if (x.title != null && x.title != "") {
      return x.title;
    } else if (x.volumeTitle != null && x.volumeTitle != "") {
      return x.volumeTitle;
    } else {
      return "[no info to show] " + x.id;
    }
  }

  associatedPublicationSelected(event) {
    if (event != null) {
      if ((event.authors != null && this.addReferenceDetail.authors != event.authors) ||
      (event.year != null && this.addReferenceDetail.year != event.year) || (event.title != null && this.addReferenceDetail.title != event.title)) {
        alert("WARNING!!! Incoherent Data\n\nReference Data:\n   Authors: " + this.addReferenceDetail.authors + "\n   Title: " + this.addReferenceDetail.title + "\n   Year: " + this.addReferenceDetail.year
          + "\nPublication Data: \n   Authors: " + event.authors + "\n   Title: " + event.title + "\n   Year: " + event.year);
      }
    }
    this.addReferenceDetail.associatedPublication = event
  }

  associatedPublicationSearch = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addReferenceDetail.associatedPublication = null;} return []}))
      : this.documentApi.readAllPublications(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null, null, null, [this.addReferenceDetail.type] ).pipe(
        map(value => {
          if (value.publications.length > 0) {
            return value.publications
          } else {
            return []
          }
        })
      ))
  )

  private addSuggestedTagsFromDB(): void {
    this.documentApi.retrieveSuggestedTags('document-detail', "tags", this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          this.suggestedTags = value.suggestions.split('||^||').filter(Boolean);
          this.suggestedTags = this.suggestedTags.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;}) // delete duplicates
        }
      }
    );
  }

   tagsGS: string[] = [];
   suggestedTagsGS: string[] = [];
   suggestedHandTags: string[] = [];

   private addHandTagsFromDB(handId: any): any {
    this.docdetail.hands[handId].handTags = []
    this.documentApi.retrieveTags('hands', handId, 'tags-hand', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          this.docdetail.hands[handId].handTags = value.value.split('||^||').filter(Boolean);
        } else {
          this.docdetail.hands[handId].handTags = []
        }
      }
    );
  }

 
   private addSuggestedTagsGSFromDB(): void {
     this.suggestedTagsGS = [];
     this.documentApi.retrieveSuggestedTags('graphic-symbols', 'tags-gs', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
       value => {
         if (value != null) {
           this.suggestedTagsGS = value.suggestions.split('||^||').filter(Boolean);
           this.suggestedTagsGS = this.suggestedTagsGS.filter(function(item, pos, self) {
              return self.indexOf(item) == pos;}) // delete duplicates
         }
       }
     );
   }

   private addSuggestedHandTagsFromDB(): void {
    this.suggestedHandTags = [];
    this.documentApi.retrieveSuggestedTags('hands', 'tags-hand', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          this.suggestedHandTags = value.suggestions.split('||^||').filter(Boolean);
          this.suggestedHandTags = this.suggestedHandTags.filter(function(item, pos, self) {
             return self.indexOf(item) == pos;}) // delete duplicates
        }
      }
    );
  }

  private updateGeneralTagsToDB(gsId: any, tags: any, table_name: string, table_field_name: string): void {
    let updatedTagsGS = {
      table: table_name,
      table_id: gsId,
      tagged_field: table_field_name,
      value: ""
    }
    if (tags != null) {
      tags.forEach(function (item, index) {
        updatedTagsGS.value = updatedTagsGS.value.concat(item);
        updatedTagsGS.value = updatedTagsGS.value.concat('||^||');
      });
      this.documentApi.updateTags(gsId, updatedTagsGS, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {}
      )
    }
  }

  urlsDR: string[] = [];

  openURLWindow(url: string): void {
    let fixed_url: string = '';
    if (!/^http[s]?:\/\//.test(url.trim())) {
      fixed_url += 'http://';
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');
  }

  openTrismegistosURLWindow(url: string): void {
    let fixed_url: string = '';
    if (this.urlsTPPrefixes.has(url)){
      fixed_url += this.urlsTPPrefixes.get(url)
      //console.log(fixed_url)
      window.open(fixed_url, '_blank');
    } else {
      fixed_url += url.trim();
      window.open(fixed_url, '_blank');
    }
    /*if (!/^http[s]?:\/\//.test(url.trim())) {
      fixed_url += this.urlsTPPrefixes.get(url)
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');*/
  }

  urlsTP: string[] = [];
  urlsTPPrefixes: Map<string,string> = new Map<string,string>();


  addPhysicalSupport(): void {
    this.router.navigate(['/psdetail'], { state: { docData: this.docdetail, urlsTP: this.urlsTP, tags: this.tags } });
  }

  handleFileInput(files: FileList) {
    this.documentApi.fileUpload(this.mainApp.user.email, this.mainApp.user.idToken, files.item(0)).subscribe(
      value => {
        this.gsDetail.picture = value.fileId
        var reader = new FileReader();
        reader.readAsDataURL(files.item(0));
        reader.onload = (_event) => {
          this.currentPictureX = reader.result;
          this.gsDetail.picture = this.currentPictureX.split(';base64,')[0] + ';base64,' + this.gsDetail.picture
        }
      })
  }

  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.documentForm.submitted || (!this.documentForm.dirty && !this.listsModified);
  }

  ngAfterViewInit(): void {
    var theUserObj = this;
    if (history.state.docData == null && this.id != null) {
      this.documentApi.readDocId(this.id, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.docdetail.id = this.id;
          this.docdetail.comment = value.comment;
          this.docdetail.docType = value.docType;
          this.docdetail.otherAuthors = value.otherAuthors;
          this.docdetail.century = value.century;
          this.docdetail.date = value.date;
          this.docdetail.dateNotes = value.dateNotes;
          this.docdetail.subject = value.subject;
          this.docdetail.originalArchive = value.originalArchive;
          this.docdetail.title = value.title;
          this.docdetail.stateComments = value.stateComments;
          this.docdetail.tags = value.tags;
          this.docdetail.language = value.language;
          this.docdetail.trismegistosUrl = value.trismegistosUrl;
          this.docdetail.sidePosition = value.sidePosition;
          if (value.maybeSymbols != null){
            this.docdetail.maybeSymbols = value.maybeSymbols.toLowerCase();
          }
          this.docdetail.origin = value.origin;
          this.docdetail.provenance = value.provenance;
          this.docdetail.associatedLocationOrigin = value.associatedLocationOrigin
          this.docdetail.associatedLocationProvenance = value.associatedLocationProvenance
          this.docdetail.uncertainOriginAssignment = value.uncertainOriginAssignment
          this.docdetail.uncertainProvenanceAssignment = value.uncertainProvenanceAssignment
          this.docdetail.associatedLocationOrigin2 = value.associatedLocationOrigin2
          this.docdetail.associatedLocationProvenance2 = value.associatedLocationProvenance2
          this.docdetail.uncertainOrigin2Assignment = value.uncertainOrigin2Assignment
          this.docdetail.uncertainProvenance2Assignment = value.uncertainProvenance2Assignment
          this.docdetail.toBeChecked = value.toBeChecked;
          this.docdetail.needReview = value.needReview;
          this.docdetail.state = value.state;
          this.docdetail.principalIdentifier = value.principalIdentifier
          this.mainIdentifierDataInit()
          this.docdetail.principalIdentifierEx = value.principalIdentifierEx
          this.mainIdentifierDataInitEx()
          if (value.physicalSupports != null) {
            for (let oi of value.physicalSupports) {
              theUserObj.docdetail.physicalSupports[oi.id] = oi
            }
          }
          this.physicalSupportDataInit()
          if (value.editions != null) {
            for (let oi of value.editions) {
              theUserObj.docdetail.editions[oi.citations[0].id] = oi
            }
          }
          this.bibliographyDataInit(theUserObj.docdetail.editions, theUserObj.editionsDataSource, theUserObj.editionPaginator, theUserObj.editionsort)
          if (value.editionsEx != null) {
            for (let oi of value.editionsEx) {
              theUserObj.docdetail.editionsEx[oi.citations[0].id] = oi
            }
          }
          this.bibliographyDataInit(theUserObj.docdetail.editionsEx, theUserObj.editionsDataSourceEx, theUserObj.editionPaginatorEx, theUserObj.editionsortEx)
          if (value.digitalReproductions != null) {
            for (let oi of value.digitalReproductions) {
              theUserObj.docdetail.digitalReproductions[oi.id] = oi
            }
          }
          this.digitalReproductionDataInit()
          if (value.bibliography != null) {
            for (let oi of value.bibliography) {
              theUserObj.docdetail.bibliography[oi.citations[0].id] = oi
            }
          }
          this.bibliographyDataInit(theUserObj.docdetail.bibliography, theUserObj.bibliographyDataSource, theUserObj.bibliographyPaginator, theUserObj.bibliographySort)
          if (value.bibliographyEx != null) {
            for (let oi of value.bibliographyEx) {
              theUserObj.docdetail.bibliographyEx[oi.citations[0].id] = oi
            }
          }
          this.bibliographyDataInit(theUserObj.docdetail.bibliographyEx, theUserObj.bibliographyDataSourceEx, theUserObj.bibliographyPaginatorEx, theUserObj.bibliographySortEx)
          if (value.otherIdentifiers != null) {
            for (let oi of value.otherIdentifiers) {
              theUserObj.docdetail.otherIdentifiers[oi.citations[0].id] = oi
            }
          }
          this.bibliographyDataInit(theUserObj.docdetail.otherIdentifiers, theUserObj.identifiersDataSource, theUserObj.identifierPaginator, theUserObj.identifiersort)
          if (value.otherIdentifiersEx != null) {
            for (let oi of value.otherIdentifiersEx) {
              theUserObj.docdetail.otherIdentifiersEx[oi.citations[0].id] = oi
            }
          }
          this.bibliographyDataInit(theUserObj.docdetail.otherIdentifiersEx, theUserObj.identifiersDataSourceEx, theUserObj.identifierPaginatorEx, theUserObj.identifiersortEx)
          if (value.printedFacsimile != null) {
            for (let oi of value.printedFacsimile) {
              theUserObj.docdetail.printedFacsimile[oi.citations[0].id] = oi
            }
          }
          this.bibliographyDataInit(theUserObj.docdetail.printedFacsimile, theUserObj.printedFacsimileDataSource, theUserObj.printedFacsimilePaginator, theUserObj.printedFacsimileSort)
          if (value.printedFacsimileEx != null) {
            for (let oi of value.printedFacsimileEx) {
              theUserObj.docdetail.printedFacsimileEx[oi.citations[0].id] = oi
            }
          }
          this.bibliographyDataInit(theUserObj.docdetail.printedFacsimileEx, theUserObj.printedFacsimileDataSourceEx, theUserObj.printedFacsimilePaginatorEx, theUserObj.printedFacsimileSortEx)
          // fill "trismegistos/papyri.info" url field
          if (this.docdetail.trismegistosUrl != null){
            this.urlsTPPrefixes.clear();
            this.urlsTP = this.docdetail.trismegistosUrl.split('||^||').filter(Boolean); // fill TP url list
            for (var i = 0; i < this.urlsTP.length; i++) { // format url string from list, show only the IDs (final part of the url)
              if (this.urlsTP[i].includes("trismegistos.org/text/")) {
                var fullUrl = this.urlsTP[i];
                var position = this.urlsTP[i].lastIndexOf("/");
                this.urlsTP[i] = fullUrl.substring(position+1); // show only the ID
                this.urlsTPPrefixes.set(this.urlsTP[i],"https://www.trismegistos.org/text/" + fullUrl.substring(position+1))
              }
            }
          } else {this.urlsTP = [];} // the list is empty
          //fill "Date" tags
          if (this.docdetail.date != '' && this.docdetail.date != null){
            this.dateTags = this.docdetail.date.split('||^||').filter(Boolean);
          } else {this.dateTags = []}
          //fill "Type" list
          if (this.docdetail.docType != null) {
            this.selectedTypes = this.docdetail.docType.split("||^||").filter(Boolean);
          } else {this.selectedTypes = []}
          //fill "Other Authors" list
          if (this.docdetail.otherAuthors != null) {
            this.selectedAuthors = this.docdetail.otherAuthors.split("||^||").filter(Boolean);
          } else {this.selectedAuthors = []}
          //fill "Century" list
          if (this.docdetail.century != null) {
            this.selectedCentury = this.docdetail.century.split("||^||").filter(Boolean);
          } else {this.selectedCentury = []}
        },
        error => console.error(JSON.stringify(error)),
        () => {
          
        });

      this.documentApi.readH(this.id, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.docdetail.hands = {}
          if (value.hands) {
            for (let hand of value.hands) {
              this.docdetail.hands[hand.id] = hand;
              this.addHandTagsFromDB(hand.id);
              this.docdetail.hands[hand.id].gss = {};
              if (hand.personId != null) {
                this.documentApi.readPid(hand.personId, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
                  value => {
                    this.docdetail.hands[hand.id].personDescription = this.stringifyPerson(value)
                    this.docdetail.hands[hand.id].person = value
                  }
                )
              }
              this.documentApi.readGSH(this.id, hand.id, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
                value => {
                  if (value.graphicSymbols) {
                    for (let gs of value.graphicSymbols) {
                      this.docdetail.hands[hand.id].gss[gs.id] = gs
                      if (gs.tags != null) {
                        this.docdetail.hands[hand.id].gss[gs.id].tags = gs.tags.split(';').filter(Boolean);
                      } else {
                        this.docdetail.hands[hand.id].gss[gs.id].tags = []
                      }
                    }
                    this.showAllGS(); // show all gs without having selected a hand before
                  }
                },
                error => console.error(JSON.stringify(error)),
              );
            }
          }
          this.handDataInit();
        },
        error => console.error(JSON.stringify(error))
      );
      // fill tags and suggestions from db for existing doc
      this.addTagsFromDB();
      this.addSuggestedTagsFromDB();
      // fill list for originalArchive's autocompleteness
      this.documentApi.getOriginalArchiveList(this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          //console.log(value.archives)
          value.archives.split('!!^!!').forEach(item => {
            if (item.trim() != ''){
              this.originalArchiveList.add(item.trim());
            }
          });
        },
        error => console.error(JSON.stringify(error)),
      );
    } else {
      setTimeout(() => {
        if (history.state.docData != null) {
          this.docdetail = history.state.docData
          this.urlsTP = history.state.urlsTP
          this.tags = history.state.tags
        } else {
          this.docdetail.toBeChecked = 0
          //this.docdetail.maybeSymbols = 'no'
          this.docdetail.state = 'inprocess'
          this.urlsTP = []; // clear trismegistos url field
          this.tags = [];
        }
        this.handDataInit()
        this.bibliographyDataInit(theUserObj.docdetail.bibliography, theUserObj.bibliographyDataSource, theUserObj.bibliographyPaginator, theUserObj.bibliographySort)
        this.bibliographyDataInit(theUserObj.docdetail.bibliographyEx, theUserObj.bibliographyDataSourceEx, theUserObj.bibliographyPaginatorEx, theUserObj.bibliographySortEx)
        this.bibliographyDataInit(theUserObj.docdetail.otherIdentifiers, theUserObj.identifiersDataSource, theUserObj.identifierPaginator, theUserObj.identifiersort)
        this.bibliographyDataInit(theUserObj.docdetail.otherIdentifiersEx, theUserObj.identifiersDataSourceEx, theUserObj.identifierPaginatorEx, theUserObj.identifiersortEx)
        this.bibliographyDataInit(theUserObj.docdetail.editions, theUserObj.editionsDataSource, theUserObj.editionPaginator, theUserObj.editionsort)
        this.bibliographyDataInit(theUserObj.docdetail.editionsEx, theUserObj.editionsDataSourceEx, theUserObj.editionPaginatorEx, theUserObj.editionsortEx)
        this.digitalReproductionDataInit()
        this.physicalSupportDataInit()
        this.mainIdentifierDataInit()
        this.mainIdentifierDataInitEx()
        this.bibliographyDataInit(theUserObj.docdetail.printedFacsimile, theUserObj.printedFacsimileDataSource, theUserObj.printedFacsimilePaginator, theUserObj.printedFacsimileSort)
        this.bibliographyDataInit(theUserObj.docdetail.printedFacsimileEx, theUserObj.printedFacsimileDataSourceEx, theUserObj.printedFacsimilePaginatorEx, theUserObj.printedFacsimileSortEx)

        this.addSuggestedTagsFromDB(); // fill suggested tags from db for new document
        // fill list for originalArchive's autocompleteness
        this.documentApi.getOriginalArchiveList(this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            //console.log(value.archives)
            value.archives.split('!!^!!').forEach(item => {
              if (item.trim() != ''){
                this.originalArchiveList.add(item.trim());
              }            
            });
          },
          error => console.error(JSON.stringify(error)),
        );
      })
    }
    setTimeout(() => {
      this.show = true;
    });
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') == null) {
      this.id = null;
      if (sessionStorage.getItem("PSaddtonewdoc") != null){ // add existing PS to new document
        var existing_psid: number =  Number(sessionStorage.getItem("PSaddtonewdoc"));
        sessionStorage.clear();
        //alert(existing_psid);
        var theUserObj = this;
        this.documentApi.readById(existing_psid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            theUserObj.docdetail.physicalSupports[value.id] = value;
            this.physicalSupportDataInit();
          }
        );
      }
    } else {
      this.id = Number(this.route.snapshot.paramMap.get('id'));
    }
    this.gsElementArray[0] = { value: 'Alphabetical Signs', checked: false }
    this.gsElementArray[1] = { value: 'Tachygraphic / Tironian notes', checked: false }
    this.gsElementArray[2] = { value: 'Strokes', checked: false }
  }

  mainIdentifierDataInit(): void {
    var theUserObj = this;
    if (theUserObj.docdetail.principalIdentifier != null) {
      theUserObj.mainIdentifierDataSource.data.push(theUserObj.docdetail.principalIdentifier);
    }
    theUserObj.mainIdentifierDataSource.data = theUserObj.mainIdentifierDataSource.data;
  }

  mainIdentifierDataInitEx(): void {
    var theUserObj = this;
    if (theUserObj.docdetail.principalIdentifierEx != null) {
      theUserObj.mainIdentifierDataSourceEx.data.push(theUserObj.docdetail.principalIdentifierEx);
    }
    theUserObj.mainIdentifierDataSourceEx.data = theUserObj.mainIdentifierDataSourceEx.data;
  }

  physicalSupportDataInit(): void {
    var theUserObj = this;
    Object.keys(this.docdetail.physicalSupports).forEach(function (drId) {
      if (theUserObj.docdetail.physicalSupports[drId].deleted == null) {
        theUserObj.physicalSupportsDataSource.data.push(theUserObj.docdetail.physicalSupports[drId]);
      }
    })
    //to initialize paginator and sort (setTimeout it's not the best solution but works)
    setTimeout(() => {
      this.physicalSupportsDataSource.paginator = this.physicalSupportPaginator;
      this.physicalSupportsDataSource.sort = this.physicalSupportSort;

    });
  }

  bibliographyDataInit(bibliographySet, dataSource, paginator, sort): void {
    var theUserObj = this;
    Object.keys(bibliographySet).forEach(function (drId) {
      if (bibliographySet[drId].deleted == null) {
        dataSource.data.push(bibliographySet[drId])
      }
    })
    //to initialize paginator and sort (setTimeout it's not the best solution but works)
    setTimeout(() => {
      dataSource.paginator = paginator;
      dataSource.sort = sort;

    });
  }

  digitalReproductionDataInit(): void {
    var theUserObj = this;
    Object.keys(this.docdetail.digitalReproductions).forEach(function (drId) {
      if (theUserObj.docdetail.digitalReproductions[drId].deleted == null) {
        theUserObj.digitalReproductionDataSource.data.push(theUserObj.docdetail.digitalReproductions[drId]);
      }
    })
    //to initialize paginator and sort (setTimeout it's not the best solution but works)
    setTimeout(() => {
      this.digitalReproductionDataSource.paginator = this.digitalReproductionPaginator;
      this.digitalReproductionDataSource.sort = this.digitalreproductionsort;

    });
  }

  handDataInit(): void {
    var theUserObj = this;
    Object.keys(this.docdetail.hands).forEach(function (handId) {
      if (theUserObj.docdetail.hands[handId].deleted == null) {
        theUserObj.handsDataSource.data.push(theUserObj.docdetail.hands[handId]);
      }
    })
    //to initialize paginator and sort (setTimeout it's not the best solution but works)
    setTimeout(() => {
      this.handsDataSource.paginator = this.handPaginator;
      this.handsDataSource.sort = this.sort;
    });
  }

  assignedToHandsDataInit(row: any): void {
    var theUserObj = this;
    theUserObj.assignedHandSelection.clear();
    theUserObj.assignedToHandsDataSource = new MatTableDataSource();
    Object.keys(this.docdetail.hands).forEach(function (handId) {
      if (theUserObj.docdetail.hands[handId].deleted == null) {
        theUserObj.assignedToHandsDataSource.data.push(theUserObj.docdetail.hands[handId]);
      }
      if (handId == row.handId){
        theUserObj.assignedHandSelection.toggle(theUserObj.docdetail.hands[handId]);
        theUserObj.handAssignedSelected = row.handId;
      }
    })
    //to initialize paginator and sort (setTimeout it's not the best solution but works)
    setTimeout(() => {
      this.assignedToHandsDataSource.paginator = this.handPaginator;
      this.assignedToHandsDataSource.sort = this.sort;
    });
  }

  assignedAddGSDataInit(selectedHandId: any): void {
    var theUserObj = this;
    theUserObj.assignedHandSelection.clear();
    theUserObj.assignedToHandsDataSource = new MatTableDataSource();
    Object.keys(this.docdetail.hands).forEach(function (handId) {
      if (theUserObj.docdetail.hands[handId].deleted == null) {
        theUserObj.assignedToHandsDataSource.data.push(theUserObj.docdetail.hands[handId]);
      }
    })
    theUserObj.assignedHandSelection.toggle(theUserObj.docdetail.hands[selectedHandId]);
    theUserObj.handAssignedSelected = selectedHandId;
    //to initialize paginator and sort (setTimeout it's not the best solution but works)
    setTimeout(() => {
      this.assignedToHandsDataSource.paginator = this.handPaginator;
      this.assignedToHandsDataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.handsDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterGraphicSymbol(filterValue: string) {
    this.graphicSymbolDataSource.filter = filterValue.trim().toLowerCase();
  }

  goBack(): void {
    this.location.back();
  }

  saveGSS(handId, gss) {
    var theUserObj = this;
    return new Promise<void>((resolve, reject) => {
      var tasks = []
      Object.keys(gss).forEach(function (gsId) {
        var gsCopy = Object.assign({}, gss[gsId])
        gsCopy.handId = handId
        if (Number(gsId) >= 0) { //GS to be updated
          var obs = theUserObj.documentApi.updateGSid(theUserObj.docdetail.id, handId, Number(gsId), gsCopy, theUserObj.mainApp.user.email, theUserObj.mainApp.user.idToken).toPromise()
          tasks.push(obs)
          obs.then(
            value => {
              if (gsCopy.deleted == true) {
                var obs = theUserObj.documentApi.deleteGSid(theUserObj.docdetail.id, handId, Number(gsId), theUserObj.mainApp.user.email, theUserObj.mainApp.user.idToken).toPromise()
                tasks.push(obs)
                obs.then(
                  value => {
                    
                  }
                )
              } else {
                theUserObj.updateGeneralTagsToDB(value.id, gss[gsId].tags, "graphic-symbols", "tags-gs")
              }
            }
          )
        } else { //GS to be created
          if (gsCopy.deleted == null) {
            let obs = theUserObj.documentApi.createGS(theUserObj.docdetail.id, Number(handId), gsCopy, theUserObj.mainApp.user.email, theUserObj.mainApp.user.idToken).toPromise()
            tasks.push(obs)
            obs.then(
              value => {
                theUserObj.updateGeneralTagsToDB(value.id, gss[gsId].tags, "graphic-symbols", "tags-gs")
              }
            )
          }
        }
      })
      if (tasks.length > 0) {
        Promise.all(tasks).then(() => resolve())
      } else {
        resolve()
      }
    })
  }

  saveHands(docid, value) {
    var theUserObj = this;
    return new Promise<void>((resolve, reject) => {
      var tasks = []
      
      Object.keys(this.docdetail.hands).forEach(function (handId) {
        var handCopy = Object.assign({}, theUserObj.docdetail.hands[handId])
        handCopy.documentId = docid
        if (Number(handId) >= 0) { //Hand to be updated
          var obs = theUserObj.documentApi.updateHid(docid, handCopy.id, handCopy, theUserObj.mainApp.user.email, theUserObj.mainApp.user.idToken).toPromise()
          tasks.push(obs)
          obs.then(
            value => {
              
              if (handCopy.deleted == true) {
                var obs = theUserObj.documentApi.deleteHid(docid, handCopy.id, theUserObj.mainApp.user.email, theUserObj.mainApp.user.idToken).toPromise()
                tasks.push(obs)
              } else {
                var promise = theUserObj.saveGSS(handId, handCopy.gss)
                tasks.push(promise)
              }
              var tagPromise = theUserObj.updateGeneralTagsToDB(handId, handCopy.handTags, "hands", "tags-hand")
              tasks.push(tagPromise)
            }
          )
        } else { //Hand to be created
          var obsHand = theUserObj.documentApi.createH(docid, handCopy, theUserObj.mainApp.user.email, theUserObj.mainApp.user.idToken).toPromise()
          tasks.push(obsHand)
          obsHand.then(
            value => {
              var promise = theUserObj.saveGSS(value.id, handCopy.gss)
              tasks.push(promise)
              var tagPromise = theUserObj.updateGeneralTagsToDB(value.id, handCopy.handTags, "hands", "tags-hand")
              tasks.push(tagPromise)
            }
          )
        }
      })
      if (tasks.length > 0) {
        Promise.all(tasks).then(() => resolve())
      } else {
        resolve()
      }
    })
  }

  save(saved): void {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    if (this.newGsRequired || this.gsSelected != null) {
      if (!this.gsForm.form.valid) {
        if (!confirm("A graphic symbol is being edited but required fields are empty, the changes to the symbol will be discarded!!! Do you want to proceed?")) {
          return;
        }
      } else {
        this.saveGS();
      }
    }
    var theUserObj = this;
    if (theUserObj.id == -1) {
      return;
    }
    
    this.saveGS();
    let docupdate = {
      id: this.docdetail.id,
      //physicalSupportId: this.docdetail.PSId,
      comment: this.docdetail.comment,
      //date: this.docdetail.date,
      date: '',
      dateNotes: this.docdetail.dateNotes,
      subject: this.docdetail.subject,
      originalArchive: this.docdetail.originalArchive,
      title: this.docdetail.title,
      language: this.docdetail.language,
      sidePosition: this.docdetail.sidePosition,
      stateComments: this.docdetail.stateComments,
      maybeSymbols: this.docdetail.maybeSymbols,
      origin: this.docdetail.origin,
      provenance: this.docdetail.provenance,
      uncertainOriginAssignment: this.docdetail.uncertainOriginAssignment,
      uncertainProvenanceAssignment: this.docdetail.uncertainProvenanceAssignment,
      uncertainOrigin2Assignment: this.docdetail.uncertainOrigin2Assignment,
      uncertainProvenance2Assignment: this.docdetail.uncertainProvenance2Assignment,
      docType: '',
      otherAuthors: '',
      century: '',
      trismegistosUrl: '',
      toBeChecked: this.docdetail.toBeChecked,
      needReview: this.docdetail.needReview,
      state: this.docdetail.state,
      principalIdentifier: null,
      principalIdentifierEx: null,
      otherIdentifiers: [],
      otherIdentifiersEx: [],
      editions: [],
      editionsEx: [],
      digitalReproductions: [],
      bibliography: [],
      bibliographyEx: [],
      physicalSupports: [],
      printedFacsimile: [],
      printedFacsimileEx: [],
      associatedLocationOrigin: null,
      associatedLocationProvenance: null,
      associatedLocationOrigin2: null,
      associatedLocationProvenance2: null
    }
    docupdate.associatedLocationOrigin = this.docdetail.associatedLocationOrigin
    docupdate.associatedLocationProvenance = this.docdetail.associatedLocationProvenance
    docupdate.uncertainOriginAssignment = this.docdetail.uncertainOriginAssignment
    docupdate.uncertainProvenanceAssignment = this.docdetail.uncertainProvenanceAssignment
    docupdate.associatedLocationOrigin2 = this.docdetail.associatedLocationOrigin2
    docupdate.associatedLocationProvenance2 = this.docdetail.associatedLocationProvenance2
    docupdate.uncertainOrigin2Assignment = this.docdetail.uncertainOrigin2Assignment
    docupdate.uncertainProvenance2Assignment = this.docdetail.uncertainProvenance2Assignment
    docupdate.principalIdentifier = this.docdetail.principalIdentifier
    docupdate.principalIdentifierEx = this.docdetail.principalIdentifierEx
    docupdate.physicalSupports = Object.values(this.docdetail.physicalSupports)
    docupdate.otherIdentifiers = Object.values(this.docdetail.otherIdentifiers)
    docupdate.otherIdentifiersEx = Object.values(this.docdetail.otherIdentifiersEx)
    docupdate.editions = Object.values(this.docdetail.editions)
    docupdate.editionsEx = Object.values(this.docdetail.editionsEx)
    docupdate.digitalReproductions = Object.values(this.docdetail.digitalReproductions)
    docupdate.bibliography = Object.values(this.docdetail.bibliography)
    docupdate.bibliographyEx = Object.values(this.docdetail.bibliographyEx)
    docupdate.printedFacsimile = Object.values(this.docdetail.printedFacsimile)
    docupdate.printedFacsimileEx = Object.values(this.docdetail.printedFacsimileEx)
    // date tags
    var typeBUFFER = ''
    this.dateTags.forEach(t => {
      typeBUFFER = typeBUFFER.concat(t)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    //console.log(this.dateTags)
    if (typeBUFFER != ''){
      docupdate.date = typeBUFFER
    } else {docupdate.date=''}
    // century
    typeBUFFER = ''
    this.selectedCentury.forEach(t => {
      typeBUFFER = typeBUFFER.concat(t)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      docupdate.century = typeBUFFER
    } else {docupdate.century=''}
    // doc type
    typeBUFFER = ''
    this.selectedTypes.forEach(t => {
      typeBUFFER = typeBUFFER.concat(t)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      docupdate.docType = typeBUFFER
    } else {docupdate.docType=''}
    // other authors
    typeBUFFER = ''
    this.selectedAuthors.forEach(t => {
      typeBUFFER = typeBUFFER.concat(t)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      docupdate.otherAuthors = typeBUFFER
    } else {docupdate.otherAuthors=''}
    docupdate.trismegistosUrl = '';
    for (var i = 0; i < this.urlsTP.length; i++) { // format url string from list
      if (this.urlsTPPrefixes.has(this.urlsTP[i])){ //the url is a trismegistos link
        docupdate.trismegistosUrl = docupdate.trismegistosUrl.concat("https://www.trismegistos.org/text/" + this.urlsTP[i]);
        docupdate.trismegistosUrl = docupdate.trismegistosUrl.concat('||^||'); // delimiter
      } else { // url different from trismegistos (papyri and other websites)
        docupdate.trismegistosUrl = docupdate.trismegistosUrl.concat(this.urlsTP[i]);
        docupdate.trismegistosUrl = docupdate.trismegistosUrl.concat('||^||'); // delimiter  
      }
    }
    
    if (theUserObj.id != null) { //WE ARE UPDATING
      this.documentApi.updateDoc(docupdate.id, docupdate, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.saveHands(docupdate.id, value).then(() => {
            this.isSaving = false;
            theUserObj.modalService.open(saved, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
              theUserObj.closeResult = 'Closed with: ${result}';
              theUserObj.router.navigateByUrl('/', { skipLocationChange: true }).then(() => { theUserObj.router.navigate(['/docdetail/' + theUserObj.id]) });
            }, (reason) => {
              theUserObj.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
              theUserObj.router.navigateByUrl('/', { skipLocationChange: true }).then(() => { theUserObj.router.navigate(['/docdetail/' + theUserObj.id]) });
            });
          }
          )
        });

      // update or create tags
      this.updateGeneralTagsToDB(this.docdetail.id, this.tags, "document-detail", "tags");
    } else {
      theUserObj.id = -1
      this.documentApi.createDoc(docupdate, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          theUserObj.docdetail.id = value.id;
          this.updateGeneralTagsToDB(value.id, this.tags, "document-detail", "tags"); // create tags entry for the new doc
          this.saveHands(value.id, value).then(() => {
            this.isSaving = false;
            theUserObj.modalService.open(saved, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
              theUserObj.closeResult = 'Closed with: ${result}';
              theUserObj.router.navigate(['/docdetail/' + value.id]);
            }, (reason) => {
              theUserObj.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
              theUserObj.router.navigate(['/docdetail/' + value.id]);
            });
          })
        })
    }
    this.listsModified = false
  }

  addPersonModal(destination) {
    const modalRef = this.modalService.open(PersonDetailModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
    modalRef.componentInstance.toModify = null
    modalRef.componentInstance.mainApp = this.mainApp
    modalRef.result.then((value) => {
      this.addHandDetail.person = value;
      this.addHandDetail.personId = value.id;
    }).catch((res) => {});
  }

  modifyPersonModal(person) {
    const modalRef = this.modalService.open(PersonDetailModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass'})
    modalRef.componentInstance.toModify = true
    modalRef.componentInstance.mainApp = this.mainApp
    modalRef.componentInstance.addPersonDetail = person
    modalRef.result.then((result) => {
      this.addHandDetail.person = result;
      this.addHandDetail.personId = result.id;
    }, (reason) => {
    }).catch((res) => {});
  }

  addHandModal(addHandTemplate, toModify = null): void {
    if (toModify == null) {
      this.addHandDetail = {
        id: this.docdetail.lastNewHandId--,
        ordinal: null,
        uncertainOrdinalAssignment: null,
        script: null,
        role: null,
        comment: null,
        position: null,
        personId: null,
        personDescription: null,
        uncertainAssignment: false,
        paleographicalAttribution: false,
        handTags: []
      }
    } else {
      this.addHandDetail = Object.assign({}, toModify);
    }
    this.addSuggestedHandTagsFromDB();
    this.modalService.open(addHandTemplate, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
      if (this.addHandDetail.personId != null) {
        this.addHandDetail.personDescription = this.stringifyPerson(this.addHandDetail.person)
      }
      if (toModify == null) {
        this.docdetail.hands[this.addHandDetail.id] = this.addHandDetail
        this.docdetail.hands[this.addHandDetail.id].gss = {}
        this.handsDataSource.data.push(this.addHandDetail);
      } else {
        let currentGss = this.docdetail.hands[this.addHandDetail.id].gss;
        this.docdetail.hands[this.addHandDetail.id] = this.addHandDetail
        this.docdetail.hands[this.addHandDetail.id].gss = currentGss;
        let theUserObj = this.addHandDetail
        let datasourceindex = this.handsDataSource.data.findIndex(function (element: any, index, array) {
          return element.id == theUserObj.id
        });
        this.handsDataSource.data[datasourceindex] = this.addHandDetail
      }
      this.handsDataSource.data = this.handsDataSource.data
      this.handSelection.clear();
      this.gsSelection.clear();
      this.handSelected = null;
      this.newGsRequired = false;
      this.gsSelected = null;
      this.listsModified = true;
      this.showAddGSbtn = false;
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });
  }

  referenceBookSeriesSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addReferenceDetail.bookSeries = null;} return [] }))
        : this.documentApi.readBookSeries(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null ).pipe(
          map(value => {
            if (value.bookSeries.length > 0) {
              return value.bookSeries
            } else {
              return []
            }
          })
        ))
    )

  refreshCitationTable(bibliographyItem, dataSource, internalArray, tableToUpdate) {
    if (internalArray != null) {
      let filteredArray = Object.keys(internalArray).filter(oi => internalArray[oi] != null && internalArray[oi].deleted == null && internalArray[oi].id == bibliographyItem.id)
      filteredArray.forEach(oi => {
        let datasourceindex = dataSource.data.findIndex(function (element: any, index, array) {
          return element.citations[0].id == oi
        });
        let theUserObj = JSON.parse(JSON.stringify(bibliographyItem))
        theUserObj.citations[0] = JSON.parse(JSON.stringify(internalArray[oi].citations[0]))
        dataSource.data[datasourceindex] = theUserObj
        internalArray[oi] = theUserObj
      })
    } else if (this.docdetail.principalIdentifier != null) {
      if (this.docdetail.principalIdentifier != null && this.docdetail.principalIdentifier.id == bibliographyItem.id) {
        let theUserObj = JSON.parse(JSON.stringify(bibliographyItem))
        theUserObj.citations[0] = JSON.parse(JSON.stringify(this.docdetail.principalIdentifier.citations[0]))
        dataSource.data[0] = theUserObj
        this.docdetail.principalIdentifier = theUserObj
      }
    }
    dataSource.data = dataSource.data //REQUIRED TO REFRESH DATA SOURCE
  }

  refreshCitationTableEx(bibliographyItem, dataSource, internalArray, tableToUpdate) {
    if (internalArray != null) {
      let filteredArray = Object.keys(internalArray).filter(oi => internalArray[oi] != null && internalArray[oi].deleted == null && internalArray[oi].id == bibliographyItem.id)
      filteredArray.forEach(oi => {
        let datasourceindex = dataSource.data.findIndex(function (element: any, index, array) {
          return element.citations[0].id == oi
        });
        let theUserObj = JSON.parse(JSON.stringify(bibliographyItem))
        theUserObj.citations[0] = JSON.parse(JSON.stringify(internalArray[oi].citations[0]))
        dataSource.data[datasourceindex] = theUserObj
        internalArray[oi] = theUserObj
      })
    } else if (this.docdetail.principalIdentifierEx != null) {
      if (this.docdetail.principalIdentifierEx != null && this.docdetail.principalIdentifierEx.id == bibliographyItem.id) {
        let theUserObj = JSON.parse(JSON.stringify(bibliographyItem))
        theUserObj.citations[0] = JSON.parse(JSON.stringify(this.docdetail.principalIdentifierEx.citations[0]))
        dataSource.data[0] = theUserObj
        this.docdetail.principalIdentifierEx = theUserObj
      }
    }
    dataSource.data = dataSource.data //REQUIRED TO REFRESH DATA SOURCE
  }

  refreshAllCitationTablesEx(bibliographyItem) {
    this.refreshCitationTableEx(bibliographyItem, this.bibliographyDataSourceEx, this.docdetail.bibliographyEx, 'bibliography') //BIBLIO
    this.refreshCitationTableEx(bibliographyItem, this.editionsDataSourceEx, this.docdetail.editionsEx, 'edition') //EDITIONS
    this.refreshCitationTableEx(bibliographyItem, this.mainIdentifierDataSourceEx, null, 'principal') //MAIN IDENTIFIER
    this.refreshCitationTableEx(bibliographyItem, this.identifiersDataSourceEx, this.docdetail.otherIdentifiersEx, 'otheridentifier') //OTHER IDENTIFIERS
    this.refreshCitationTableEx(bibliographyItem, this.printedFacsimileDataSourceEx, this.docdetail.printedFacsimileEx, 'facsimile') //FAC-SIMILE
  }
  
  refreshAllCitationTables(bibliographyItem) {
    this.refreshCitationTable(bibliographyItem, this.bibliographyDataSource, this.docdetail.bibliography, 'bibliography') //BIBLIO
    this.refreshCitationTable(bibliographyItem, this.editionsDataSource, this.docdetail.editions, 'edition') //EDITIONS
    this.refreshCitationTable(bibliographyItem, this.mainIdentifierDataSource, null, 'principal') //MAIN IDENTIFIER
    this.refreshCitationTable(bibliographyItem, this.identifiersDataSource, this.docdetail.otherIdentifiers, 'otheridentifier') //OTHER IDENTIFIERS
    this.refreshCitationTable(bibliographyItem, this.printedFacsimileDataSource, this.docdetail.printedFacsimile, 'facsimile') //FAC-SIMILE
  }

  addReferenceModal(referenceModal, toModify = null): void {
    if (toModify == null) {
      this.addReferenceDetail.windowTitle = 'Add reference'
      this.addReferenceDetail.bookSeries = null;
      this.addReferenceDetail.title = null;
      this.addReferenceDetail.volume = null;
      this.addReferenceDetail.authors = null;
      this.addReferenceDetail.year = null;
      this.addReferenceDetail.referenceText = null;
      this.addReferenceDetail.referenceTextType = null;
      this.addReferenceDetail.type = null;
      this.addReferenceDetail.associatedPublication = null;
    } else {
      this.addReferenceDetail = Object.assign({}, toModify);
      this.addReferenceDetail.windowTitle = 'Modify reference'
    }

    this.modalService.open(referenceModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass' }).result.then((result) => {
      if (this.addReferenceDetail.referenceTextType != 'Other') {
        if (this.addReferenceDetail.referenceTextType == "1") {
          this.addReferenceDetail.referenceText = this.addReferenceDetail.bookSeries.abbreviation + ' ' + this.addReferenceDetail.volume
        } else if (this.addReferenceDetail.referenceTextType == "2") {
          this.addReferenceDetail.referenceText = this.addReferenceDetail.authors + ' ' + this.addReferenceDetail.year
        } else if (this.addReferenceDetail.referenceTextType == "3") {
          this.addReferenceDetail.referenceText = this.addReferenceDetail.bookSeries.abbreviation + ' ' + this.addReferenceDetail.volume + ' ' + this.addReferenceDetail.year
        } else if (this.addReferenceDetail.referenceTextType == "4") {
          this.addReferenceDetail.referenceText = this.addReferenceDetail.bookSeries.abbreviation + ' ' + this.addReferenceDetail.year
        } else if (this.addReferenceDetail.referenceTextType == "5") {
          this.addReferenceDetail.referenceText = this.addReferenceDetail.authors + ' ' + this.addReferenceDetail.title
        }
      }
      if (toModify == null) {
        this.documentApi.createReference(this.addReferenceDetail, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.addReferenceDetail.id = value.id;
            this.addCitationDetail.reference = this.addReferenceDetail;
          }
        )
      } else {
        this.documentApi.updateReferenceId(toModify.id, this.addReferenceDetail, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.addCitationDetail.reference = this.addReferenceDetail;
            this.addCitationDetail.id = this.addReferenceDetail.id;
            this.addCitationDetail.title = this.addReferenceDetail.title;
            this.addCitationDetail.volume = this.addReferenceDetail.volume;
            this.addCitationDetail.authors = this.addReferenceDetail.authors;
            this.addCitationDetail.type= this.addReferenceDetail.type;
            this.addCitationDetail.year= this.addReferenceDetail.year;
            this.addCitationDetail.referenceText= this.addReferenceDetail.referenceText;
            this.addCitationDetail.referenceTextType= this.addReferenceDetail.referenceTextType;
            this.addCitationDetail.bookSeries= Object.assign({}, this.addReferenceDetail.bookSeries);
            this.addCitationDetail.associatedPublication= Object.assign({}, this.addReferenceDetail.associatedPublication)
          }
        )
      }
    }, (reason) => {
    });
  }

  addBibliographyModal(addBibliographyModalTemplate, idWindow, toModify = null): void {
    let potentialNegativeId = this.docdetail.lastNewBibliographyId--
    if (toModify == null) {
      this.addBibliographyDetail = {
        createNew: true,
        id: null,
        idWindowDbg: idWindow,
        authors: null,
        title: null,
        place: null,
        plate: null,
        tome: null,
        comment: null,
        year: null,
        url: null,
        pages: null,
        book: null,
        number: null,
        citations: [
          {
            id: potentialNegativeId,
            documentSpecificPages: null,
            documentSpecificPlate: null,
            documentSpecificTome: null,
            documentSpecificNumber: null,
            documentSpecificComment: null
          }
        ]
      }
    } else {
      this.addBibliographyDetail = Object.assign({}, toModify)
      this.addBibliographyDetail.idWindowDbg = idWindow
    }
    var dataSource = null
    var internalArray = null
    if (toModify == null) {
      this.addBibliographyDetail.windowTitle = 'Add '
    } else {
      this.addBibliographyDetail.windowTitle = 'Modify '
    }
    if (idWindow == 1 /*BIBLIO*/) {
      this.addBibliographyDetail.windowTitle += 'bibliography item'
      dataSource = this.bibliographyDataSource
      internalArray = this.docdetail.bibliography
    } else if (idWindow == 2 /*EDITION*/) {
      this.addBibliographyDetail.windowTitle += 'edition'
      dataSource = this.editionsDataSource
      internalArray = this.docdetail.editions
    } else if (idWindow == 3 /*IDENTIFIER*/) {
      this.addBibliographyDetail.windowTitle += 'main identifier'
      dataSource = this.mainIdentifierDataSource
    } else if (idWindow == 4 /*OTHERIDENTIFIER*/) {
      this.addBibliographyDetail.windowTitle += 'other identifier'
      dataSource = this.identifiersDataSource
      internalArray = this.docdetail.otherIdentifiers
    } else if (idWindow == 5 /*PRINTEDFACSIMILE*/) {
      this.addBibliographyDetail.windowTitle += 'printed fac-simile'
      dataSource = this.printedFacsimileDataSource
      internalArray = this.docdetail.printedFacsimile
    }
    this.modalService.open(addBibliographyModalTemplate, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
      if (this.addBibliographyDetail.createNew == true) {
        this.addBibliographyDetail.id = null
      }
      this.addBibliographyDetail.modified = true

      let apiCall = null
      if (this.addBibliographyDetail.createNew == true) {
        apiCall = this.documentApi.createBibliography(this.addBibliographyDetail, this.mainApp.user.email, this.mainApp.user.idToken)
      } else {
        apiCall = this.documentApi.updateBibliographyId(this.addBibliographyDetail.id, this.addBibliographyDetail, this.mainApp.user.email, this.mainApp.user.idToken)
      }
      apiCall.subscribe(result => {
        if (result.id != null) {
          this.addBibliographyDetail.id = result.id
        }
        if (idWindow == 3) {
          this.docdetail.principalIdentifier = this.addBibliographyDetail
        } else {
          internalArray[this.addBibliographyDetail.citations[0].id] = this.addBibliographyDetail
        }
        if (toModify == null) {
          if (idWindow == 3) {
            dataSource.data.splice(0, dataSource.data.length)
          }
          dataSource.data.push(this.addBibliographyDetail);
        } else {
          let theUserObj = this.addBibliographyDetail
          let datasourceindex = dataSource.data.findIndex(function (element: any, index, array) {
            return element.citations[0].id == theUserObj.citations[0].id
          });
          dataSource.data[datasourceindex] = this.addBibliographyDetail
        }
        dataSource.data = dataSource.data
        this.refreshAllCitationTables(this.addBibliographyDetail)
        this.listsModified = true;
      })
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });
  }

  addCitationModal(addCitationModalTemplate, idWindow, toModify = null): void {
    let potentialNegativeId = this.docdetail.lastNewCitationId--
    if (toModify == null) {
      this.addCitationDetail = {
        createNew: true,
        id: null,
        idWindowDbg: idWindow,
        reference: null,
        citations: [
          {
            id: potentialNegativeId,
            documentSpecificPages: null,
            documentSpecificPlate: null,
            documentSpecificTome: null,
            documentSpecificNumber: null,
            documentSpecificComment: null
          }
        ]
      }
    } else {
      this.addCitationDetail = Object.assign({}, toModify)
      if (this.addCitationDetail.reference == null || this.addCitationDetail.reference == undefined) {
        this.addCitationDetail.reference = {
          id: toModify.id,
          title: toModify.title,
          volume: toModify.volume,
          authors: toModify.authors,
          type: toModify.type,
          year: toModify.year,
          referenceText: toModify.referenceText,
          referenceTextType: toModify.referenceTextType,
          bookSeries: Object.assign({}, toModify.bookSeries),
          associatedPublication: Object.assign({}, toModify.associatedPublication)
        }
      }
      this.addCitationDetail.idWindowDbg = idWindow
    }
    var dataSource = null
    var internalArray = null
    if (toModify == null) {
      this.addCitationDetail.windowTitle = 'Add '
    } else {
      this.addCitationDetail.windowTitle = 'Modify '
    }
    if (idWindow == 1 /*BIBLIO*/) {
      this.addCitationDetail.windowTitle += 'bibliography item'
      dataSource = this.bibliographyDataSourceEx
      internalArray = this.docdetail.bibliographyEx
    } else if (idWindow == 2 /*EDITION*/) {
      this.addCitationDetail.windowTitle += 'edition'
      dataSource = this.editionsDataSourceEx
      internalArray = this.docdetail.editionsEx
    } else if (idWindow == 3 /*IDENTIFIER*/) {
      this.addCitationDetail.windowTitle += 'main identifier'
      dataSource = this.mainIdentifierDataSourceEx
    } else if (idWindow == 4 /*OTHERIDENTIFIER*/) {
      this.addCitationDetail.windowTitle += 'other identifier'
      dataSource = this.identifiersDataSourceEx
      internalArray = this.docdetail.otherIdentifiersEx
    } else if (idWindow == 5 /*PRINTEDFACSIMILE*/) {
      this.addCitationDetail.windowTitle += 'printed fac-simile'
      dataSource = this.printedFacsimileDataSourceEx
      internalArray = this.docdetail.printedFacsimileEx
    }
    this.modalService.open(addCitationModalTemplate, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
      this.addCitationDetail.modified = true
      
        if (idWindow == 3) {
          this.docdetail.principalIdentifierEx = this.addCitationDetail
        } else {
          internalArray[this.addCitationDetail.citations[0].id] = this.addCitationDetail
        }
        if (toModify == null) {
          if (idWindow == 3) {
            dataSource.data.splice(0, dataSource.data.length)
          }
          dataSource.data.push(this.addCitationDetail);
        } else {
          let theUserObj = this.addCitationDetail
          let datasourceindex = dataSource.data.findIndex(function (element: any, index, array) {
            return element.citations[0].id == theUserObj.citations[0].id
          });
          dataSource.data[datasourceindex] = this.addCitationDetail
        }
        dataSource.data = dataSource.data
        this.refreshAllCitationTablesEx(this.addCitationDetail)
        this.listsModified = true;
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });
  }

  addDigitalReproductionModal(addDigitalReproductionModalTemplate, toModify = null): void {
    if (toModify == null) {
      this.addDigitalReproductionDetail = {
        id: this.docdetail.lastNewDigitalReproductionId--,
        url: null
      }
      this.urlsDR = []; // url list is initially empty
    } else {
      this.addDigitalReproductionDetail = Object.assign({}, toModify);
      if (this.addDigitalReproductionDetail.url != null){
        this.urlsDR = this.addDigitalReproductionDetail.url.split('||^||').filter(Boolean); // fill url list
      } else {this.urlsDR = [];} // url list is empty
    }
    this.modalService.open(addDigitalReproductionModalTemplate, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
      this.addDigitalReproductionDetail.url = '';
      for (var i = 0; i < this.urlsDR.length; i++) {
        this.addDigitalReproductionDetail.url = this.addDigitalReproductionDetail.url.concat(this.urlsDR[i]);
        this.addDigitalReproductionDetail.url = this.addDigitalReproductionDetail.url.concat('||^||'); // delimiter
      }
      this.docdetail.digitalReproductions[this.addDigitalReproductionDetail.id] = this.addDigitalReproductionDetail
      if (toModify == null) {
        this.digitalReproductionDataSource.data.push(this.addDigitalReproductionDetail);
      } else {
        let theUserObj = this.addDigitalReproductionDetail
        let datasourceindex = this.digitalReproductionDataSource.data.findIndex(function (element: any, index, array) {
          return element.id == theUserObj.id
        });
        this.digitalReproductionDataSource.data[datasourceindex] = this.addDigitalReproductionDetail
      }
      this.digitalReproductionDataSource.data = this.digitalReproductionDataSource.data //REQUIRED TO REFRESH DATA SOURCE
      this.listsModified = true;
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });
  }

  addBookV2Modal(addBookModal): void {
    this.addBookV2Detail.bookSeries = null;
    this.addBookV2Detail.bookSeriesId = null;
    this.addBookV2Detail.title = null;
    this.addBookV2Detail.volume = null;
    this.addBookV2Detail.authors = null;
    this.addBookV2Detail.place = null;
    this.addBookV2Detail.year = null;
    this.addBookV2Detail.titleOther = null;
    this.addBookV2Detail.publicationVolume = null;
    this.addBookV2Detail.type = null;
    this.addBookV2Detail.extendedTitle = null;
    this.modalService.open(addBookModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
      let newBook: Book = {
        bookSeries: {
          id: this.addBookV2Detail.bookSeriesId
        },
        title: this.addBookV2Detail.title,
        volume: this.addBookV2Detail.volume,
        authors: this.addBookV2Detail.authors,
        place: this.addBookV2Detail.place,
        year: this.addBookV2Detail.year,
        extendedTitle: this.addBookV2Detail.extendedTitle,
        publicationVolume: this.addBookV2Detail.publicationVolume,
        type: this.addBookV2Detail.type
      }
      if (this.addBookV2Detail.titleOther != 'Other') {
        if (this.addBookV2Detail.titleOther == "1") {
          newBook.title = this.addBookV2Detail.bookSeries.abbreviation + ' ' + this.addBookV2Detail.volume
        } else if (this.addBookV2Detail.titleOther == "2") {
          newBook.title = this.addBookV2Detail.authors + ' ' + this.addBookV2Detail.year
        } else if (this.addBookV2Detail.titleOther == "3") {
          newBook.title = this.addBookV2Detail.bookSeries.abbreviation + ' ' + this.addBookV2Detail.volume + ' ' + this.addBookV2Detail.year
        } else if (this.addBookV2Detail.titleOther == "4") {
          newBook.title = this.addBookV2Detail.bookSeries.abbreviation + ' ' + this.addBookV2Detail.year
        }
      }
      this.documentApi.createBook(newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.addBibliographyDetail.book = value;
          this.addBibliographyDetail.year = newBook.year;
        }
      )
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });
  }

  addContainerBookSeriesModal(addBookSeriesModal): void {
    this.addContainerBookSeriesDetail.title = null;
    this.addContainerBookSeriesDetail.abbreviation = null;
    this.modalService.open(addBookSeriesModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
      let newBook: BookSeries = {
        extendedTitle: this.addContainerBookSeriesDetail.title,
        abbreviation: this.addContainerBookSeriesDetail.abbreviation,
      }
      this.documentApi.createBookSeries(newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.addBookSeriesDetail.containerSeriesId = value.id;
          this.addBookSeriesDetail.containerSeries = value;
        }
      )
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });
  }

  addBookSeriesModal(addBookSeriesModal): void {
    this.addBookSeriesDetail.title = null;
    this.addBookSeriesDetail.abbreviation = null;
    this.addBookSeriesDetail.containerSeries = null,
      this.addBookSeriesDetail.containerSeriesId = null
    this.modalService.open(addBookSeriesModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
      let newBook: BookSeries = {
        extendedTitle: this.addBookSeriesDetail.title,
        abbreviation: this.addBookSeriesDetail.abbreviation,
        containerBookSeries: {
          id: this.addBookSeriesDetail.containerSeriesId
        }
      }
      this.documentApi.createBookSeries(newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.addBookV2Detail.bookSeriesId = value.id;
          this.addBookV2Detail.bookSeries = value;
          this.addReferenceDetail.bookSeriesId = value.id;
          this.addReferenceDetail.bookSeries = value;
        }
      )
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });
  }

  addLocationModal(toModify, destinationBuffer = this.docdetail, fromScreen = 0, type): void {
    let urls: string[] = [];
    if (type == 'origin'){
      if (toModify == null || toModify.id == null) {
        destinationBuffer.windowTitle = 'Add Origin'
        destinationBuffer.containerPublication = null;
        destinationBuffer.name = null;
        destinationBuffer.latitude = null;
        destinationBuffer.longitude = null;
        destinationBuffer.year = null;
        destinationBuffer.url = null;
        this.addSuggestedTagsOriFromDB();
        this.initOtherOriginTags(destinationBuffer);
      } else {
        if (fromScreen == 1 || fromScreen == 0) {
          this.addLocationOriginDetail = Object.assign({}, toModify);
          destinationBuffer = this.addLocationOriginDetail;
        } else if (fromScreen == 2) {
          this.addContainerLocationOriginDetail = Object.assign({}, toModify);
          destinationBuffer = this.addContainerLocationOriginDetail;
        }
        destinationBuffer.windowTitle = 'Modify Origin';
        if (destinationBuffer.url != null){
          urls = destinationBuffer.url.split('||^||').filter(Boolean); // fill url list
        }
        this.addTagsOriFromDB();
        this.addSuggestedTagsOriFromDB();
        this.initOtherOriginTags(destinationBuffer);
      }
      const modalRef = this.modalService.open(DocumentLocationOriginModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
      modalRef.componentInstance.addLocationOriginDetail = destinationBuffer
      modalRef.componentInstance.urls = urls
      modalRef.componentInstance.alternativeNamesOriTags = this.alternativeNamesOriTags
      modalRef.componentInstance.urlsTrismegistosOri = this.urlsTrismegistosOri
      modalRef.componentInstance.urlsPleiadesOri = this.urlsPleiadesOri
      modalRef.componentInstance.urlsSeeFurtherOri = this.urlsSeeFurtherOri
      modalRef.componentInstance.documentComponent = this
      modalRef.result.then((result) => {
        destinationBuffer.modified = true;
        destinationBuffer.url = '';
        for (var i = 0; i < urls.length; i++) {
          destinationBuffer.url = destinationBuffer.url.concat(urls[i]);
          destinationBuffer.url = destinationBuffer.url.concat('||^||'); // delimiter
        }
        this.updateOtherOriginTags(destinationBuffer)
        if (toModify == null || toModify.id == null) {
          this.documentApi.createLoc(destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {
              //this.loadPublications(this.lastFilter, false)
              this.updateGeneralTagsToDB(value.id, this.tagsOri, 'locations', 'tags-loc')
              if (fromScreen == 1) {
                this.docdetail.associatedLocationOrigin = value;
              } else if (fromScreen == 2) {
                this.addLocationOriginDetail.containerPublication = value;
                this.addLocationOriginDetail.year = value.year;
              }
            }
          )
        } else {
          destinationBuffer.citations = [] //TODO: Troppi dati inviati nel caso di update
          this.updateOtherOriginTags(destinationBuffer)
          this.documentApi.updateLocById(toModify.id, destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {
              //this.loadPublications(this.lastFilter, false)
              if (fromScreen == 1) {
                this.docdetail.associatedLocationOrigin = value;
              } else if (fromScreen == 2) {
                this.addLocationOriginDetail.containerPublication = value;
                this.addLocationOriginDetail.year = value.year;
              }
            }
          )
          this.updateGeneralTagsToDB(this.addLocationOriginDetail.id, this.tagsOri, 'locations', 'tags-loc')
          //  this.updateOtherOriginTags(this.addLocationOriginDetail)
        }
      }, (reason) => {
      });
    } else if (type == 'provenance'){
      if (toModify == null || toModify.id == null) {
        destinationBuffer.windowTitle = 'Add Provenance'
        destinationBuffer.containerPublication = null;
        destinationBuffer.name = null;
        destinationBuffer.latitude = null;
        destinationBuffer.longitude = null;
        destinationBuffer.year = null;
        destinationBuffer.url = null;
        this.addSuggestedTagsProFromDB();
        this.initOtherProvenanceTags(destinationBuffer);
      } else {
        if (fromScreen == 1 || fromScreen == 0) {
          this.addLocationProvenanceDetail = Object.assign({}, toModify);
          destinationBuffer = this.addLocationProvenanceDetail;
        } else if (fromScreen == 2) {
          this.addContainerLocationProvenanceDetail = Object.assign({}, toModify);
          destinationBuffer = this.addContainerLocationProvenanceDetail;
        }
        destinationBuffer.windowTitle = 'Modify Provenance';
        if (destinationBuffer.url != null){
          urls = destinationBuffer.url.split('||^||').filter(Boolean); // fill url list
        }
        this.addTagsProFromDB();
        this.addSuggestedTagsProFromDB();
        this.initOtherProvenanceTags(destinationBuffer);
      }
      const modalRef = this.modalService.open(DocumentLocationProvenanceModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
      modalRef.componentInstance.addLocationProvenanceDetail = destinationBuffer
      modalRef.componentInstance.urls = urls
      modalRef.componentInstance.alternativeNamesProTags = this.alternativeNamesProTags
      modalRef.componentInstance.urlsTrismegistosPro = this.urlsTrismegistosPro
      modalRef.componentInstance.urlsPleiadesPro = this.urlsPleiadesPro
      modalRef.componentInstance.urlsSeeFurtherPro = this.urlsSeeFurtherPro
      modalRef.componentInstance.documentComponent = this
      modalRef.result.then((result) => {
        destinationBuffer.modified = true;
        destinationBuffer.url = '';
        for (var i = 0; i < urls.length; i++) {
          destinationBuffer.url = destinationBuffer.url.concat(urls[i]);
          destinationBuffer.url = destinationBuffer.url.concat('||^||'); // delimiter
        }
        if (toModify == null || toModify.id == null) {
          this.updateOtherProvenanceTags(destinationBuffer)
          this.documentApi.createLoc(destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {
              //this.loadPublications(this.lastFilter, false)
              this.updateGeneralTagsToDB(value.id, this.tagsPro, 'locations', 'tags-loc')
              if (fromScreen == 1) {
                this.docdetail.associatedLocationProvenance = value;
              } else if (fromScreen == 2) {
                this.addLocationProvenanceDetail.containerPublication = value;
                this.addLocationProvenanceDetail.year = value.year;
              }
            }
          )
        } else {
          destinationBuffer.citations = [] //TODO: Troppi dati inviati nel caso di update
          this.updateOtherProvenanceTags(destinationBuffer)
          //console.log(destinationBuffer)
          this.documentApi.updateLocById(toModify.id, destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {
              //this.loadPublications(this.lastFilter, false)
              if (fromScreen == 1) {
                this.docdetail.associatedLocationProvenance = value;
              } else if (fromScreen == 2) {
                this.addLocationProvenanceDetail.containerPublication = value;
                this.addLocationProvenanceDetail.year = value.year;
              }
            }
          )
          this.updateGeneralTagsToDB(this.addLocationProvenanceDetail.id, this.tagsPro, 'locations', 'tags-loc')
          //this.updateOtherProvenanceTags(this.addLocationProvenanceDetail)
        }
      }, (reason) => {
      });
    }
  }

  addLocationModal2(toModify, destinationBuffer = this.docdetail, fromScreen = 0, type): void {
    let urls: string[] = [];
    if (type == 'origin2'){
      if (toModify == null || toModify.id == null) {
        destinationBuffer.windowTitle = 'Add Origin'
        destinationBuffer.containerPublication = null;
        destinationBuffer.name = null;
        destinationBuffer.latitude = null;
        destinationBuffer.longitude = null;
        destinationBuffer.year = null;
        destinationBuffer.url = null;
        this.addSuggestedTagsOriFromDB();
        this.initOtherOriginTags(destinationBuffer);
      } else {
        if (fromScreen == 1 || fromScreen == 0) {
          this.addLocationOriginDetail2 = Object.assign({}, toModify);
          destinationBuffer = this.addLocationOriginDetail2;
        } else if (fromScreen == 2) {
          this.addContainerLocationOriginDetail2 = Object.assign({}, toModify);
          destinationBuffer = this.addContainerLocationOriginDetail2;
        }
        destinationBuffer.windowTitle = 'Modify Origin';
        if (destinationBuffer.url != null){
          urls = destinationBuffer.url.split('||^||').filter(Boolean); // fill url list
        }
        this.addTagsOri2FromDB();
        this.addSuggestedTagsOriFromDB();
        this.initOtherOriginTags(destinationBuffer);
      }
      const modalRef = this.modalService.open(DocumentLocationOriginModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
      modalRef.componentInstance.addLocationOriginDetail = destinationBuffer
      modalRef.componentInstance.urls = urls
      modalRef.componentInstance.alternativeNamesOriTags = this.alternativeNamesOriTags
      modalRef.componentInstance.urlsTrismegistosOri = this.urlsTrismegistosOri
      modalRef.componentInstance.urlsPleiadesOri = this.urlsPleiadesOri
      modalRef.componentInstance.urlsSeeFurtherOri = this.urlsSeeFurtherOri
      modalRef.componentInstance.documentComponent = this
      modalRef.result.then((result) => {
        destinationBuffer.modified = true;
        destinationBuffer.url = '';
        for (var i = 0; i < urls.length; i++) {
          destinationBuffer.url = destinationBuffer.url.concat(urls[i]);
          destinationBuffer.url = destinationBuffer.url.concat('||^||'); // delimiter
        }
        this.updateOtherOriginTags(destinationBuffer)
        if (toModify == null || toModify.id == null) {
          this.documentApi.createLoc(destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {
              //this.loadPublications(this.lastFilter, false)
              this.updateGeneralTagsToDB(value.id, this.tagsOri2, 'locations', 'tags-loc')
              if (fromScreen == 1) {
                this.docdetail.associatedLocationOrigin2 = value;
              } else if (fromScreen == 2) {
                this.addLocationOriginDetail2.containerPublication = value;
                this.addLocationOriginDetail2.year = value.year;
              }
            }
          )
        } else {
          destinationBuffer.citations = [] //TODO: Troppi dati inviati nel caso di update
          this.updateOtherOriginTags(destinationBuffer)
          this.documentApi.updateLocById(toModify.id, destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {
              //this.loadPublications(this.lastFilter, false)
              if (fromScreen == 1) {
                this.docdetail.associatedLocationOrigin2 = value;
              } else if (fromScreen == 2) {
                this.addLocationOriginDetail2.containerPublication = value;
                this.addLocationOriginDetail2.year = value.year;
              }
            }
          )
          this.updateGeneralTagsToDB(this.addLocationOriginDetail2.id, this.tagsOri2, 'locations', 'tags-loc')
          //  this.updateOtherOriginTags(this.addLocationOriginDetail2)
        }
      }, (reason) => {
      });
    } else if (type == 'provenance2'){
      if (toModify == null || toModify.id == null) {
        destinationBuffer.windowTitle = 'Add Provenance'
        destinationBuffer.containerPublication = null;
        destinationBuffer.name = null;
        destinationBuffer.latitude = null;
        destinationBuffer.longitude = null;
        destinationBuffer.year = null;
        destinationBuffer.url = null;
        this.addSuggestedTagsProFromDB();
        this.initOtherProvenanceTags(destinationBuffer);
      } else {
        if (fromScreen == 1 || fromScreen == 0) {
          this.addLocationProvenanceDetail2 = Object.assign({}, toModify);
          destinationBuffer = this.addLocationProvenanceDetail2;
        } else if (fromScreen == 2) {
          this.addContainerLocationProvenanceDetail2 = Object.assign({}, toModify);
          destinationBuffer = this.addContainerLocationProvenanceDetail2;
        }
        destinationBuffer.windowTitle = 'Modify Provenance';
        if (destinationBuffer.url != null){
          urls = destinationBuffer.url.split('||^||').filter(Boolean); // fill url list
        }
        this.addTagsPro2FromDB();
        this.addSuggestedTagsProFromDB();
        this.initOtherProvenanceTags(destinationBuffer);
      }
      const modalRef = this.modalService.open(DocumentLocationProvenanceModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
      modalRef.componentInstance.addLocationProvenanceDetail = destinationBuffer
      modalRef.componentInstance.urls = urls
      modalRef.componentInstance.alternativeNamesProTags = this.alternativeNamesProTags
      modalRef.componentInstance.urlsTrismegistosPro = this.urlsTrismegistosPro
      modalRef.componentInstance.urlsPleiadesPro = this.urlsPleiadesPro
      modalRef.componentInstance.urlsSeeFurtherPro = this.urlsSeeFurtherPro
      modalRef.componentInstance.documentComponent = this
      modalRef.result.then((result) => {
        destinationBuffer.modified = true;
        destinationBuffer.url = '';
        for (var i = 0; i < urls.length; i++) {
          destinationBuffer.url = destinationBuffer.url.concat(urls[i]);
          destinationBuffer.url = destinationBuffer.url.concat('||^||'); // delimiter
        }
        if (toModify == null || toModify.id == null) {
          this.updateOtherProvenanceTags(destinationBuffer)
          this.documentApi.createLoc(destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {
              //this.loadPublications(this.lastFilter, false)
              this.updateGeneralTagsToDB(value.id, this.tagsPro2, 'locations', 'tags-loc')
              if (fromScreen == 1) {
                this.docdetail.associatedLocationProvenance2 = value;
              } else if (fromScreen == 2) {
                this.addLocationProvenanceDetail2.containerPublication = value;
                this.addLocationProvenanceDetail2.year = value.year;
              }
            }
          )
        } else {
          destinationBuffer.citations = [] //TODO: Troppi dati inviati nel caso di update
          this.updateOtherProvenanceTags(destinationBuffer)
          //console.log(destinationBuffer)
          this.documentApi.updateLocById(toModify.id, destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {
              //this.loadPublications(this.lastFilter, false)
              if (fromScreen == 1) {
                this.docdetail.associatedLocationProvenance2 = value;
              } else if (fromScreen == 2) {
                this.addLocationProvenanceDetail2.containerPublication = value;
                this.addLocationProvenanceDetail2.year = value.year;
              }
            }
          )
          this.updateGeneralTagsToDB(this.addLocationProvenanceDetail2.id, this.tagsPro2, 'locations', 'tags-loc')
          //this.updateOtherProvenanceTags(this.addLocationProvenanceDetail)
        }
      }, (reason) => {
      });
    }
  }

  alternativeNamesProTags: string[] = [];
  urlsTrismegistosPro: string[] = [];
  urlsPleiadesPro: string[] = [];
  urlsSeeFurtherPro: string[] = [];
  alternativeNamesOriTags: string[] = [];
  urlsTrismegistosOri: string[] = [];
  urlsPleiadesOri: string[] = [];
  urlsSeeFurtherOri: string[] = [];

  private initOtherOriginTags(addLocationOriginDetail: any){
    this.alternativeNamesOriTags = []
    this.urlsTrismegistosOri = []
    this.urlsPleiadesOri = []
    this.urlsSeeFurtherOri = []
    if (addLocationOriginDetail.alternativeNames != '' && addLocationOriginDetail.alternativeNames != null){
      this.alternativeNamesOriTags = addLocationOriginDetail.alternativeNames.split('||^||').filter(Boolean);
    }
    if (addLocationOriginDetail.urlsTrismegistos != '' && addLocationOriginDetail.urlsTrismegistos != null){
      this.urlsTrismegistosOri = addLocationOriginDetail.urlsTrismegistos.split('||^||').filter(Boolean);
    }
    if (addLocationOriginDetail.urlsPleiades != '' && addLocationOriginDetail.urlsPleiades != null){
      this.urlsPleiadesOri = addLocationOriginDetail.urlsPleiades.split('||^||').filter(Boolean);
    }
    if (addLocationOriginDetail.urlsSeeFurther != '' && addLocationOriginDetail.urlsSeeFurther != null){
      this.urlsSeeFurtherOri = addLocationOriginDetail.urlsSeeFurther.split('||^||').filter(Boolean);
    }
  }

  private updateOtherOriginTags(addLocationOriginDetail: any){
    // alternative names
    var typeBUFFER = ''
    this.alternativeNamesOriTags.forEach(name => {
      typeBUFFER = typeBUFFER.concat(name)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationOriginDetail.alternativeNames = typeBUFFER
    } else {addLocationOriginDetail.alternativeNames = ''}
    //trismegistos url
    typeBUFFER = ''
    this.urlsTrismegistosOri.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationOriginDetail.urlsTrismegistos = typeBUFFER
    } else {addLocationOriginDetail.urlsTrismegistos = ''}
    //pleiades url
    typeBUFFER = ''
    this.urlsPleiadesOri.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationOriginDetail.urlsPleiades = typeBUFFER
    } else {addLocationOriginDetail.urlsPleiades = ''}
    //see further url
    typeBUFFER = ''
    this.urlsSeeFurtherOri.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationOriginDetail.urlsSeeFurther = typeBUFFER
    } else {addLocationOriginDetail.urlsSeeFurther = ''}
  }

  private initOtherProvenanceTags(addLocationProvenanceDetail: any){
    this.alternativeNamesProTags = []
    this.urlsTrismegistosPro = []
    this.urlsPleiadesPro = []
    this.urlsSeeFurtherPro = []
    if (addLocationProvenanceDetail.alternativeNames != '' && addLocationProvenanceDetail.alternativeNames != null){
      this.alternativeNamesProTags = addLocationProvenanceDetail.alternativeNames.split('||^||').filter(Boolean);
    }
    if (addLocationProvenanceDetail.urlsTrismegistos != '' && addLocationProvenanceDetail.urlsTrismegistos != null){
      this.urlsTrismegistosPro = addLocationProvenanceDetail.urlsTrismegistos.split('||^||').filter(Boolean);
    }
    if (addLocationProvenanceDetail.urlsPleiades != '' && addLocationProvenanceDetail.urlsPleiades != null){
      this.urlsPleiadesPro = addLocationProvenanceDetail.urlsPleiades.split('||^||').filter(Boolean);
    }
    if (addLocationProvenanceDetail.urlsSeeFurther != '' && addLocationProvenanceDetail.urlsSeeFurther != null){
      this.urlsSeeFurtherPro = addLocationProvenanceDetail.urlsSeeFurther.split('||^||').filter(Boolean);
    }
  }

  private updateOtherProvenanceTags(addLocationProvenanceDetail: any){
    // alternative names
    var typeBUFFER = ''
    this.alternativeNamesProTags.forEach(name => {
      typeBUFFER = typeBUFFER.concat(name)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationProvenanceDetail.alternativeNames = typeBUFFER
    } else {addLocationProvenanceDetail.alternativeNames = ''}
    //trismegistos url
    typeBUFFER = ''
    this.urlsTrismegistosPro.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationProvenanceDetail.urlsTrismegistos = typeBUFFER
    } else {addLocationProvenanceDetail.urlsTrismegistos = ''}
    //pleiades url
    typeBUFFER = ''
    this.urlsPleiadesPro.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationProvenanceDetail.urlsPleiades = typeBUFFER
    } else {addLocationProvenanceDetail.urlsPleiades = ''}
    //see further url
    typeBUFFER = ''
    this.urlsSeeFurtherPro.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationProvenanceDetail.urlsSeeFurther = typeBUFFER
    } else {addLocationProvenanceDetail.urlsSeeFurther = ''}
  }


  associatedLocationOriginSelected(event) {
    let associationAllowed = true;
    if (associationAllowed == true) {
      this.docdetail.associatedLocationOrigin = event
    } else {
      this.docdetail.associatedLocationOrigin = null
    }
  }

  associatedLocationProvenanceSelected(event) {
    let associationAllowed = true;
    if (associationAllowed == true) {
      this.docdetail.associatedLocationProvenance = event
    } else {
      this.docdetail.associatedLocationProvenance = null
    }
  }

  associatedLocationFormatter = (x: Loc) => {
    return this.stringifyAssociatedLocation(x)
  }

  stringifyAssociatedLocation(x: Loc): string {
    if (x.id == null) {
      return null
    }
    if (x.name != null && x.name != ''){
      return x.id + ' - ' + x.name;
    } 
    else {
      return x.id + ' - [no name]';
    }
  }

  associatedLocationOriginSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.docdetail.associatedLocationOrigin = null;} return []}))
        : this.documentApi.readAllLocations(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null, null).pipe(
          map(value => {
            if (value.locations.length > 0) {
              value.locations.sort((a,b) => (parseInt(a.year) == null ? 0 : parseInt(a.year)) - (parseInt(b.year) == null ? 0 : parseInt(b.year)));
              return value.locations
            } else {
              return []
            }
          })
        ))
    )

    associatedLocationProvenanceSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.docdetail.associatedLocationProvenance = null;} return []}))
        : this.documentApi.readAllLocations(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null, null).pipe(
          map(value => {
            if (value.locations.length > 0) {
              value.locations.sort((a,b) => (parseInt(a.year) == null ? 0 : parseInt(a.year)) - (parseInt(b.year) == null ? 0 : parseInt(b.year)));
              return value.locations
            } else {
              return []
            }
          })
        ))
    )

    associatedLocationOrigin2Selected(event) {
      let associationAllowed = true;
      if (associationAllowed == true) {
        this.docdetail.associatedLocationOrigin2 = event
      } else {
        this.docdetail.associatedLocationOrigin2 = null
      }
    }
  
    associatedLocationProvenance2Selected(event) {
      let associationAllowed = true;
      if (associationAllowed == true) {
        this.docdetail.associatedLocationProvenance2 = event
      } else {
        this.docdetail.associatedLocationProvenance2 = null
      }
    }
  
    associatedLocationOrigin2Search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.docdetail.associatedLocationOrigin2 = null;} return []}))
          : this.documentApi.readAllLocations(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null, null).pipe(
            map(value => {
              if (value.locations.length > 0) {
                value.locations.sort((a,b) => (parseInt(a.year) == null ? 0 : parseInt(a.year)) - (parseInt(b.year) == null ? 0 : parseInt(b.year)));
                return value.locations
              } else {
                return []
              }
            })
          ))
      )
  
      associatedLocationProvenance2Search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.docdetail.associatedLocationProvenance2 = null;} return []}))
          : this.documentApi.readAllLocations(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null, null).pipe(
            map(value => {
              if (value.locations.length > 0) {
                value.locations.sort((a,b) => (parseInt(a.year) == null ? 0 : parseInt(a.year)) - (parseInt(b.year) == null ? 0 : parseInt(b.year)));
                return value.locations
              } else {
                return []
              }
            })
          ))
      )

    //Origin TAGS code
tagsOri: string[] = [];
tagsOri2: string[] = [];
suggestedTagsOri: string[] = [];

private addTagsOriFromDB(): void {
  this.documentApi.retrieveTags('locations', this.addLocationOriginDetail.id, 'tags-loc', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
    value => {
      if (value != null) {
        //console.log(value.value);
        this.tagsOri = value.value.split('||^||').filter(Boolean);
      }
    }
  );
}

private addTagsOri2FromDB(): void {
  this.documentApi.retrieveTags('locations', this.addLocationOriginDetail2.id, 'tags-loc', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
    value => {
      if (value != null) {
        //console.log(value.value);
        this.tagsOri2 = value.value.split('||^||').filter(Boolean);
      }
    }
  );
}

private addSuggestedTagsOriFromDB(): void {
  this.documentApi.retrieveSuggestedTags('locations', 'tags-loc', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
    value => {
      if (value != null) {
        //console.log(value);
        this.suggestedTagsOri = value.suggestions.split('||^||').filter(Boolean);
        this.suggestedTagsOri = this.suggestedTagsOri.filter(function(item, pos, self) {
          return self.indexOf(item) == pos;}) // delete duplicates
      }
    }
  );
}

tagsPro: string[] = [];
tagsPro2: string[] = [];
suggestedTagsPro: string[] = [];

 private addTagsProFromDB(): void {
   this.documentApi.retrieveTags('locations', this.addLocationProvenanceDetail.id, 'tags-loc', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
     value => {
       if (value != null) {
         //console.log(value.value);
         this.tagsPro = value.value.split('||^||').filter(Boolean);
       }
     }
   );
 }

 private addTagsPro2FromDB(): void {
  this.documentApi.retrieveTags('locations', this.addLocationProvenanceDetail2.id, 'tags-loc', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
    value => {
      if (value != null) {
        //console.log(value.value);
        this.tagsPro2 = value.value.split('||^||').filter(Boolean);
      }
    }
  );
}

 private addSuggestedTagsProFromDB(): void {
   this.documentApi.retrieveSuggestedTags('locations', 'tags-loc', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
     value => {
       if (value != null) {
         //console.log(value);
         this.suggestedTagsPro = value.suggestions.split('||^||').filter(Boolean);
         this.suggestedTagsPro = this.suggestedTagsPro.filter(function(item, pos, self) {
           return self.indexOf(item) == pos;}) // delete duplicates
       }
     }
   );
 }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  gsDataInit(gss: any): void {
    var theUserObj = this;
    Object.keys(gss).forEach(function (gsId) {
      if (gss[gsId].deleted == null) {
        theUserObj.graphicSymbolDataSource.data.push(gss[gsId]);
      }
    })
    //to initialize paginator and sort (setTimeout it's not the best solution but works)
    setTimeout(() => {
      this.graphicSymbolDataSource.paginator = this.gsPaginator;
      this.graphicSymbolDataSource.sort = this.gssort;
    });
  }

  showAllGS(): void {
    var theUserObj = this;
    theUserObj.graphicSymbolDataSource = new MatTableDataSource();
    if (theUserObj.docdetail.hands){
      Object.keys(theUserObj.docdetail.hands).forEach(function (handId) {
        Object.keys(theUserObj.docdetail.hands[handId].gss).forEach(function (gsId) {
          if (theUserObj.docdetail.hands[handId].gss[gsId].deleted == null) {
            theUserObj.graphicSymbolDataSource.data.push(theUserObj.docdetail.hands[handId].gss[gsId]);
          }
        });
      });
    }
    //to initialize paginator and sort (setTimeout it's not the best solution but works)
    setTimeout(() => {
      this.graphicSymbolDataSource.paginator = this.gsPaginator;
      this.graphicSymbolDataSource.sort = this.gssort;
    });
  }

  selectHand(row: any): void {
    if (this.newGsRequired || this.gsSelected != null) {
      if (!this.gsForm.form.valid) {
        if (!confirm("A graphic symbol is being edited but required fields are empty, the changes to the symbol will be discarded!!! Do you want to proceed?")) {
          return;
        }
      } else {
        this.saveGS();
      }
    }
    this.handSelection.toggle(row);
    this.gsSelection.clear();
    this.currentPictureX = null;
    if (this.handSelection.isSelected(row)) {
      this.graphicSymbolDataSource = new MatTableDataSource();
      this.gsDataInit(this.docdetail.hands[row.id].gss);
      this.handSelected = row.id;
      this.showAddGSbtn = true;
    } else {
      this.handSelected = null;
      this.showAddGSbtn = false;
      this.showAllGS()
    }
    this.newGsRequired = false;
    this.gsSelected = null;
  }

  assignHandFromGS(row: any): void{
    this.assignedHandSelection.toggle(row);
    this.handAssignedSelected = row.id;
    //console.log(row.id);
  }

  checkboxFromString(s, elementArray): void {
    Object.keys(elementArray).forEach(function (drId) {
      if (s != null) {
        if (s.includes(elementArray[drId].value + ";")) {
          elementArray[drId].checked = true
        } else {
          elementArray[drId].checked = false
        }
      }
    })
  }

  stringFromCheckbox(elementArray): string {
    let s = null
    Object.keys(elementArray).forEach(function (drId) {
      if (elementArray[drId].checked == true) {
        if (s == null) {
          s = ""
        }
        s += elementArray[drId].value + "; "
      }
    })
    return s
  }

  selectGS(row: any): void {
    if (this.newGsRequired || this.gsSelected != null) {
      if (!this.gsForm.form.valid) {
        if (!confirm("A graphic symbol is being edited but required fields are empty, the changes to the symbol will be discarded!!! Do you want to proceed?")) {
          return;
        }
      } else {
        this.saveGS();
      }
    }
    this.assignedToHandsDataInit(row);
    this.gsSelection.toggle(row);
    if (row.handId != null) this.handSelected = row.handId;
    this.currentPictureX = null;
    this.tagsGS = [];
    if (this.gsSelection.isSelected(row)) {
      this.gsSelected = row.id;
      this.newGsRequired = false;
      this.gsDetail = Object.assign({}, this.docdetail.hands[this.handSelected].gss[row.id])
      this.checkboxFromString(this.gsDetail.elements, this.gsElementArray)
      this.tagsGS = this.gsDetail.tags;
      this.addSuggestedTagsGSFromDB();
      //fill "Other Authors" list
      if (this.gsDetail.otherAuthors != null) {
        this.selectedAuthorsGS = this.gsDetail.otherAuthors.split("||^||").filter(Boolean);
      } else {this.selectedAuthorsGS = []}
      if (this.gsDetail.picture != null) {
        let pictureSplit = this.gsDetail.picture.split(';base64,')
        this.documentApi.fileDownload(pictureSplit[1], this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.currentPictureX = pictureSplit[0] + ';base64,' + value.file
          }
        )
      }
    } else {
      this.tagsGS = [];
      this.addSuggestedTagsGSFromDB();
      this.gsSelected = null;
      this.newGsRequired = false;
    }
  }

  addGraphicSymbol(): void {
    if (this.newGsRequired || this.gsSelected != null) {
      if (!this.gsForm.form.valid) {
        alert("A graphic symbol is being edited but required fields are empty, the changes to the symbol will be discarded!!!")
      } else {
        this.saveGS();
      }
    }
    this.currentPictureX = null
    this.gsDetail = { elementArray: {} }
    this.gsElementArray[0].checked = false
    this.gsElementArray[1].checked = false
    this.gsElementArray[2].checked = false
    this.gsSelected = null;
    this.gsSelection.clear();
    this.newGsRequired = true;
    this.tagsGS = [];
    this.addSuggestedTagsGSFromDB();
    this.assignedAddGSDataInit(this.handSelected);
  }

  discardGS(): void {
    this.gsDetail = {}
    this.gsSelected = null;
    this.gsSelection.clear();
    this.newGsRequired = false;
    this.tagsGS = [];
    this.suggestedTagsGS = [];
  }

  saveGS(): void {
    if (this.handSelected == null || this.docdetail.hands[this.handSelected] == null || this.docdetail.hands[this.handAssignedSelected] == null) {
      return;
    }
    if (this.handAssignedSelected != null){
      this.gsDetail.handOrdinal = this.docdetail.hands[this.handAssignedSelected].ordinal
      //console.log(this.docdetail.hands[this.handAssignedSelected].ordinal)
    } else {
      this.gsDetail.handOrdinal = this.docdetail.hands[this.handSelected].ordinal
    }
    if (this.newGsRequired) {
      this.gsDetail.id = this.docdetail.lastNewGraphicSymbolId--
      this.gsDetail.elements = this.stringFromCheckbox(this.gsElementArray)
      this.gsDetail.tags = Object.assign([], this.tagsGS);
      // other authors
      var typeBUFFER = ''
      this.selectedAuthorsGS.forEach(t => {
        typeBUFFER = typeBUFFER.concat(t)
        typeBUFFER = typeBUFFER.concat('||^||')//delimiter
      });
      if (typeBUFFER != ''){
        this.gsDetail.otherAuthors = typeBUFFER
      } else {this.gsDetail.otherAuthors=''}
      if (this.handAssignedSelected != null){
        this.gsDetail.handId = this.handAssignedSelected;
        this.docdetail.hands[this.handAssignedSelected].gss[this.gsDetail.id] = Object.assign({}, this.gsDetail)
      } else {
        this.gsDetail.handId = this.handSelected;
        this.docdetail.hands[this.handSelected].gss[this.gsDetail.id] = Object.assign({}, this.gsDetail)
      }
      this.graphicSymbolDataSource.data.push(this.gsDetail);
      this.graphicSymbolDataSource.data = this.graphicSymbolDataSource.data //REQUIRED TO REFRESH DATA SOURCE
      //this.createTagsGSToDB(this.gsDetail.id);
      this.newGsRequired = null;
      this.gsDetail = {}
    } else if (this.gsSelected != null) {
      this.gsDetail.elements = this.stringFromCheckbox(this.gsElementArray)
      this.gsDetail.tags = Object.assign([], this.tagsGS);
      // other authors
      var typeBUFFER = ''
      this.selectedAuthorsGS.forEach(t => {
        typeBUFFER = typeBUFFER.concat(t)
        typeBUFFER = typeBUFFER.concat('||^||')//delimiter
      });
      if (typeBUFFER != ''){
        this.gsDetail.otherAuthors = typeBUFFER
      } else {this.gsDetail.otherAuthors=''}
      if (this.handAssignedSelected != null){
        this.docdetail.hands[this.handAssignedSelected].gss[this.gsDetail.id] = Object.assign({}, this.gsDetail)
      } else {
        this.docdetail.hands[this.handSelected].gss[this.gsDetail.id] = Object.assign({}, this.gsDetail)
      }
      var theUserObj = this;
      let datasourceindex = this.graphicSymbolDataSource.data.findIndex(function (element: any, index, array) {
        return element.id == theUserObj.gsDetail.id
      });
      if (this.handAssignedSelected != null){
        this.docdetail.hands[this.handAssignedSelected].gss[this.gsDetail.id] = this.gsDetail
        var theUserObj = this;
        Object.keys(theUserObj.docdetail.hands).forEach(hand => {
          //console.log("hand is: " + hand)
          if (hand != theUserObj.handAssignedSelected +"") {
            if (theUserObj.docdetail.hands[hand].gss[theUserObj.gsDetail.id]){
              if (theUserObj.docdetail.hands[hand].gss[theUserObj.gsDetail.id].id == theUserObj.gsDetail.id){
                delete theUserObj.docdetail.hands[hand].gss[theUserObj.gsDetail.id]
                //console.log("deleted " + theUserObj.gsDetail.id + ", hand "+ theUserObj.docdetail.hands[hand].id)
              }
            }
          }
        });
        this.graphicSymbolDataSource.data[datasourceindex] = this.docdetail.hands[this.handAssignedSelected].gss[this.gsDetail.id]  
      } else {
        this.graphicSymbolDataSource.data[datasourceindex] = this.docdetail.hands[this.handSelected].gss[this.gsDetail.id]
      }
      //console.log("here " + this.handAssignedSelected)

      this.graphicSymbolDataSource.data = this.graphicSymbolDataSource.data
      this.gsDetail = {}
      this.gsSelected = null;
      this.gsSelection.clear();
      this.newGsRequired = false;
      this.tagsGS = [];
      this.suggestedTagsGS = [];
    }
    this.listsModified = true;
  }

  deleteBibliography(biblioId: number): void {
    if (confirm("Are you sure you want to delete the selected bibliography item?")) {
      //delete this.docdetail.otherIdentifiers[identifierId]
      this.docdetail.bibliography[biblioId].deleted = true
      let datasourceindex = this.bibliographyDataSource.data.findIndex(function (element: any, index, array) {
        return element.citations[0].id == biblioId
      });
      this.bibliographyDataSource.data.splice(datasourceindex, 1)
      this.bibliographyDataSource.data = this.bibliographyDataSource.data
      this.listsModified = true;
    }
  }
  deleteBibliographyEx(biblioId: number): void {
    if (confirm("Are you sure to delete the selected bibliography item?")) {
      //delete this.docdetail.otherIdentifiers[identifierId]
      this.docdetail.bibliographyEx[biblioId].deleted = true
      let datasourceindex = this.bibliographyDataSourceEx.data.findIndex(function (element: any, index, array) {
        return element.citations[0].id == biblioId
      });
      this.bibliographyDataSourceEx.data.splice(datasourceindex, 1)
      this.bibliographyDataSourceEx.data = this.bibliographyDataSourceEx.data
      this.listsModified = true;
    }
  }

  deletePhysicalSupport(psId: number): void {
    if (confirm("Are you sure to remove the association with the selected material support?")) {
      //delete this.docdetail.otherIdentifiers[identifierId]
      this.docdetail.physicalSupports[psId].deleted = true
      let datasourceindex = this.physicalSupportsDataSource.data.findIndex(function (element: any, index, array) {
        return element.id == psId
      });
      this.physicalSupportsDataSource.data.splice(datasourceindex, 1)
      this.physicalSupportsDataSource.data = this.physicalSupportsDataSource.data
      this.listsModified = true;
    }
  }

  deleteIdentifier(identifierId: number): void {
    if (confirm("Are you sure to delete the selected identifier?")) {
      //delete this.docdetail.otherIdentifiers[identifierId]
      this.docdetail.otherIdentifiers[identifierId].deleted = true
      let datasourceindex = this.identifiersDataSource.data.findIndex(function (element: any, index, array) {
        return element.citations[0].id == identifierId
      });
      this.identifiersDataSource.data.splice(datasourceindex, 1)
      this.identifiersDataSource.data = this.identifiersDataSource.data
      this.listsModified = true;
    }
  }
  deleteIdentifierEx(identifierId: number): void {
    if (confirm("Are you sure to delete the selected identifier?")) {
      //delete this.docdetail.otherIdentifiers[identifierId]
      this.docdetail.otherIdentifiersEx[identifierId].deleted = true
      let datasourceindex = this.identifiersDataSourceEx.data.findIndex(function (element: any, index, array) {
        return element.citations[0].id == identifierId
      });
      this.identifiersDataSourceEx.data.splice(datasourceindex, 1)
      this.identifiersDataSourceEx.data = this.identifiersDataSourceEx.data
      this.listsModified = true;
    }
  }

  deleteEdition(editionId: number): void {
    if (confirm("Are you sure to delete the selected edition?")) {
      //delete this.docdetail.editions[editionId]
      this.docdetail.editions[editionId].deleted = true
      let datasourceindex = this.editionsDataSource.data.findIndex(function (element: any, index, array) {
        return element.citations[0].id == editionId
      });
      this.editionsDataSource.data.splice(datasourceindex, 1)
      this.editionsDataSource.data = this.editionsDataSource.data
      this.listsModified = true;
    }
  }
  deleteEditionEx(editionId: number): void {
    if (confirm("Are you sure to delete the selected edition?")) {
      //delete this.docdetail.editions[editionId]
      this.docdetail.editionsEx[editionId].deleted = true
      let datasourceindex = this.editionsDataSourceEx.data.findIndex(function (element: any, index, array) {
        return element.citations[0].id == editionId
      });
      this.editionsDataSourceEx.data.splice(datasourceindex, 1)
      this.editionsDataSourceEx.data = this.editionsDataSourceEx.data
      this.listsModified = true;
    }
  }

  deletePrintedFacsimile(fsId: number): void {
    if (confirm("Are you sure to delete the selected printed fac-simile?")) {
      //delete this.docdetail.editions[editionId]
      this.docdetail.printedFacsimile[fsId].deleted = true
      let datasourceindex = this.printedFacsimileDataSource.data.findIndex(function (element: any, index, array) {
        return element.citations[0].id == fsId
      });
      this.printedFacsimileDataSource.data.splice(datasourceindex, 1)
      this.printedFacsimileDataSource.data = this.printedFacsimileDataSource.data
      this.listsModified = true;
    }
  }
  deletePrintedFacsimileEx(fsId: number): void {
    if (confirm("Are you sure to delete the selected printed fac-simile?")) {
      //delete this.docdetail.editions[editionId]
      this.docdetail.printedFacsimileEx[fsId].deleted = true
      let datasourceindex = this.printedFacsimileDataSourceEx.data.findIndex(function (element: any, index, array) {
        return element.citations[0].id == fsId
      });
      this.printedFacsimileDataSourceEx.data.splice(datasourceindex, 1)
      this.printedFacsimileDataSourceEx.data = this.printedFacsimileDataSourceEx.data
      this.listsModified = true;
    }
  }

  deleteDigitalReproduction(dpId: number): void {
    if (confirm("Are you sure to delete the selected digital reproduction?")) {
      //delete this.docdetail.digitalReproductions[dpId]
      this.docdetail.digitalReproductions[dpId].deleted = true
      let datasourceindex = this.digitalReproductionDataSource.data.findIndex(function (element: any, index, array) {
        return element.id == dpId
      });
      this.digitalReproductionDataSource.data.splice(datasourceindex, 1)
      this.digitalReproductionDataSource.data = this.digitalReproductionDataSource.data
      this.listsModified = true;
    }
  }

  deleteHand(handId: number): void {
    if (confirm("Are you sure to delete the selected hand and associated graphic symbols?")) {
      //delete this.docdetail.hands[handId]
      if (handId == this.handSelected) {
        this.handSelection.clear();
        this.gsSelection.clear();
        this.currentPictureX = null;
        this.handSelected = null;
        this.showAddGSbtn = false;
        this.newGsRequired = false;
        this.gsSelected = null;
      }
      this.docdetail.hands[handId].deleted = true
      let datasourceindex = this.handsDataSource.data.findIndex(function (element: any, index, array) {
        return element.id == handId
      });
      this.handsDataSource.data.splice(datasourceindex, 1)
      this.handsDataSource.data = this.handsDataSource.data
      this.listsModified = true;
    }
  }

  deleteGraphicSymbol(handId: number, gsId: number): void {
    if (confirm("Are you sure to delete the selected graphic symbol?")) {
      //delete this.docdetail.hands[handId].gss[gsId]
      if (handId == null ) handId = this.handSelected;
      this.docdetail.hands[handId].gss[gsId].deleted = true
      let datasourceindex = this.graphicSymbolDataSource.data.findIndex(function (element: any, index, array) {
        return element.id == gsId
      });
      this.graphicSymbolDataSource.data.splice(datasourceindex, 1)
      this.graphicSymbolDataSource.data = this.graphicSymbolDataSource.data
      if (this.gsSelected == gsId) {
        this.gsSelected = null
      }
      this.listsModified = true;
    }
  }

  modifyPhysicalSupport(ps): void {
    //this.router.navigate(['/psdetail/' + ps.id], { state: { docData: this.docdetail } });
    window.open('/psdetail/' + ps.id, '_blank');
  }

  // image popup overlay
  showImagePopup = false;

  private openImagePopup(value: boolean): void {
    this.showImagePopup = value;
  }
}

@Component({
  selector: 'ngbd-modal-confirm',
  templateUrl: './document-detail-origin.component.html',
  styleUrls: ['./document-detail.component.css']
})
export class DocumentLocationOriginModal implements OnInit {
  constructor(public modal: NgbActiveModal,
    private documentApi: DefaultService) { }

  @Input() public addLocationDetail;
  @Input() public urls;
  @Input() public documentComponent;
  @Input() public alternativeNamesOriTags: string[] = [];
  @Input() public urlsTrismegistosOri: string[] = [];
  @Input() public urlsPleiadesOri: string[] = [];
  @Input() public urlsSeeFurtherOri: string[] = [];


  ngOnInit() {
    this.makeMap();
  }

  ngAfterViewInit() {
    
  }

  
  makeMap(){
    var geojson,
      //tileServer = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      tileServer = 'http://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
      tileAttribution = 'Map data: <a href="http://openstreetmap.org">OSM</a>',
      marker,
      latitude,
      longitude,
      map = L.map('map').setView([20, 30], 4);

    //Add basemap
    L.tileLayer(tileServer, {attribution: tileAttribution,  maxZoom: 15, noWrap: true}).addTo(map);

    var psIcon = L.icon({
      iconUrl: './assets/images/psIcon.png'
    });

    var event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });


    map.on('click', function(e) {
      if(marker){
        map.removeLayer(marker)
      }
      marker = L.marker((<any>e).latlng, {icon: psIcon}).addTo(map);
      latitude = (<any>e).latlng.lat;
      longitude = (<any>e).latlng.lng;
      var popupContent = '<span class="attribute"><span class="label">'+'place'+':</span> '+(<HTMLInputElement>document.getElementById('NewBookV2Name')).value+'</span>'
      popupContent +=  '<span class="attribute"><span class="label">'+'Latitude'+':</span> '+latitude+'</span>'
      popupContent +=  '<span class="attribute"><span class="label">'+'Longitude'+':</span> '+longitude+'</span>'
      popupContent = '<div class="map-popup">'+popupContent+'</div>';
      marker.bindPopup(popupContent,{offset: L.point(1,-2)});
      (<HTMLInputElement>document.getElementById('NewBookV2Longitude')).value = longitude;
      (<HTMLInputElement>document.getElementById('NewBookV2Latitude')).value = latitude;
      document.getElementById('NewBookV2Latitude').dispatchEvent(new Event('input'))
      document.getElementById('NewBookV2Longitude').dispatchEvent(new Event('input'))
    });

    d3.select("#map-container")
      .style('display','none')
      .style('position','fixed')
      .style('top','20%')
      .style('right',0)
      .style('left',0)
      .style('bottom',0)
      .style('z-index','1');
      
    d3.select('#close')
      .style('position','absolute')
      .style('display','block')
      .style('top','1%')
      .style('right','17.5%')
      .style('background-color','red')
      .style('border-radius','10px')
      .style('color','white')
      .style('padding','10px 15px 10px 15px')
      .style('margin','auto')
      .style('z-index','1000')
      .on('mouseover',function (d){
        d3.select(this).style('cursor','pointer')
      });

    d3.select('#showMapBtn').on('click', function(){
      d3.select("#map-container").style('display','block');
      d3.select("#addPSLocContainer").style('filter','blur(12px)');
    });
    d3.select('#close').on('click', function(){
      d3.select("#map-container").style('display','none');
      d3.select("#addPSLocContainer").style('filter','none');
    })

  }
  
  openURLWindow(url: string): void {
    let fixed_url: string = '';
    if (!/^http[s]?:\/\//.test(url.trim())) {
      fixed_url += 'http://';
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');
  }
}

@Component({
  selector: 'ngbd-modal-confirm',
  templateUrl: './document-detail-provenance.component.html',
  styleUrls: ['./document-detail.component.css']
})
export class DocumentLocationProvenanceModal implements OnInit {
  constructor(public modal: NgbActiveModal,
    private documentApi: DefaultService) { }

  @Input() public addLocationDetail;
  @Input() public urls;
  @Input() public documentComponent;
  @Input() public alternativeNamesProTags: string[] = [];
  @Input() public urlsTrismegistosPro: string[] = [];
  @Input() public urlsPleiadesPro: string[] = [];
  @Input() public urlsSeeFurtherPro: string[] = [];


  ngOnInit() {
    this.makeMap();
  }

  ngAfterViewInit() {
    
  }

  makeMap(){
    var geojson,
      //tileServer = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      tileServer = 'http://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
      tileAttribution = 'Map data: <a href="http://openstreetmap.org">OSM</a>',
      marker,
      latitude,
      longitude,
      map = L.map('map').setView([20, 30], 4);

    //Add basemap
    L.tileLayer(tileServer, {attribution: tileAttribution,  maxZoom: 15, noWrap: true}).addTo(map);

    var psIcon = L.icon({
      iconUrl: './assets/images/psIcon.png'
    });

    var event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });


    map.on('click', function(e) {
      if(marker){
        map.removeLayer(marker)
      }
      marker = L.marker((<any>e).latlng, {icon: psIcon}).addTo(map);
      latitude = (<any>e).latlng.lat;
      longitude = (<any>e).latlng.lng;
      var popupContent = '<span class="attribute"><span class="label">'+'place'+':</span> '+(<HTMLInputElement>document.getElementById('NewBookV2Name')).value+'</span>'
      popupContent +=  '<span class="attribute"><span class="label">'+'Latitude'+':</span> '+latitude+'</span>'
      popupContent +=  '<span class="attribute"><span class="label">'+'Longitude'+':</span> '+longitude+'</span>'
      popupContent = '<div class="map-popup">'+popupContent+'</div>';
      marker.bindPopup(popupContent,{offset: L.point(1,-2)});
      (<HTMLInputElement>document.getElementById('NewBookV2Longitude')).value = longitude;
      (<HTMLInputElement>document.getElementById('NewBookV2Latitude')).value = latitude;
      document.getElementById('NewBookV2Latitude').dispatchEvent(new Event('input'))
      document.getElementById('NewBookV2Longitude').dispatchEvent(new Event('input'))
    });

    d3.select("#map-container")
      .style('display','none')
      .style('position','fixed')
      .style('top','20%')
      .style('right',0)
      .style('left',0)
      .style('bottom',0)
      .style('z-index','1');
      
    d3.select('#close')
      .style('position','absolute')
      .style('display','block')
      .style('top','1%')
      .style('right','17.5%')
      .style('background-color','red')
      .style('border-radius','10px')
      .style('color','white')
      .style('padding','10px 15px 10px 15px')
      .style('margin','auto')
      .style('z-index','1000')
      .on('mouseover',function (d){
        d3.select(this).style('cursor','pointer')
      });

    d3.select('#showMapBtn').on('click', function(){
      d3.select("#map-container").style('display','block');
      d3.select("#addPSLocContainer").style('filter','blur(12px)');
    });
    d3.select('#close').on('click', function(){
      d3.select("#map-container").style('display','none');
      d3.select("#addPSLocContainer").style('filter','none');
    })

  }
  openURLWindow(url: string): void {
    let fixed_url: string = '';
    if (!/^http[s]?:\/\//.test(url.trim())) {
      fixed_url += 'http://';
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');
  }
}
