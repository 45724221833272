import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APIS, BASE_PATH } from './rest';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DocumentsComponent } from './documents/documents.component';
import { PhysicalsupportComponent } from './physicalsupport/physicalsupport.component';
import { DocumentDetailComponent, DocumentLocationOriginModal, DocumentLocationProvenanceModal, PublicationSearchModal } from './document-detail/document-detail.component';
import { PublicationComponent, PublicationCitationModal } from './publications/publications.component';
import { BibliographyComponent, BibliographyCitationModal, BibliographyPublicationModal, BibliographyReferenceModal, BibliographyBookSeriesModal} from './bibliography/bibliography.component';
import { PersonComponent, PersonDetailModal } from './persons/persons.component';
import { GraphicSymbolComponent, GsDetailModal } from './graphicsymbols/graphicsymbols.component';
import { MatInputModule} from '@angular/material/input';
import { MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatChipsModule} from '@angular/material/chips'
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {MatPaginatorModule} from '@angular/material/paginator'
import {MatSortModule} from '@angular/material/sort'
import {MatToolbarModule} from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { AddPhysicalsupportComponent, PhysicalSupportLocationModal } from './add-physicalsupport/add-physicalsupport.component';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from "angularx-social-login";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatNativeDateModule} from '@angular/material/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AdvancedEditorControlComponent} from './custom-controls/advanced-editor.component'
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster'

import { PendingChangesGuard } from './pending-changes.guard'
import { TimeoutInterceptor } from 'src/interceptors/TimeoutInterceptor';
import { AddLocationComponent } from './add-location/add-location.component';
import { LocationComponent } from './locations/locations.component';
import { LocMapComponent } from './locmap/locmap.component';
import { TagFieldControlComponent } from './custom-controls/tag-field.component';
import { TagDateFieldControlComponent } from './custom-controls/tagDate-field.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    AppComponent,
    DocumentsComponent,
    PhysicalsupportComponent,
    DocumentDetailComponent,
    PublicationComponent,
    BibliographyComponent,
    AddPhysicalsupportComponent,
    PersonComponent,
    GraphicSymbolComponent,
    PublicationSearchModal,
    PublicationCitationModal,
    BibliographyCitationModal,
    BibliographyPublicationModal,
    BibliographyReferenceModal,
    BibliographyBookSeriesModal,
    PersonDetailModal,
    GsDetailModal,
    AdvancedEditorControlComponent,
    TagFieldControlComponent,
    TagDateFieldControlComponent,
    LocationComponent,
    AddLocationComponent,
    LocMapComponent,
    DocumentLocationOriginModal, DocumentLocationProvenanceModal, PhysicalSupportLocationModal
  ],
  entryComponents: [PersonDetailModal, PublicationSearchModal, PublicationCitationModal, BibliographyCitationModal, BibliographyPublicationModal,
    BibliographyReferenceModal, BibliographyBookSeriesModal,DocumentLocationOriginModal, DocumentLocationProvenanceModal, PhysicalSupportLocationModal],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatInputModule,
    MatTableModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatSelectModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    NgbModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    PendingChangesGuard,
    APIS,
    { provide: BASE_PATH, useValue: 'https://sys-api.notae-erc.eu'},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider("934850580232-fcj0tjbn99v18vtsi0bbmfng8s6ii12a.apps.googleusercontent.com")
          }
        ]
      } as SocialAuthServiceConfig
    },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
